import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Flex, Grid } from "theme-ui"
import { Card } from "../common/Card"
import { Tag } from "../common/Tag"
import { getSchoolProgrammesFocus } from "../../utils/data"

const StoryItem = ({
  story: storyNode,
  aspectRatio,
  featuredImage,
  numColumns,
}) => {
  const story = storyNode.node || storyNode

  const numDescriptionColumns = numColumns === 1 ? 2 : 1

  const tags = getSchoolProgrammesFocus(
    story.schoolsFocus,
    story.programmesFocus,
  )

  return (
    <Box>
      <Flex backgroundColor="background" sx={{ height: "100%" }}>
        <Card
          image={story.featuredImage?.image?.asset?.fluid}
          imageProps={{ fluidOverrides: { aspectRatio: aspectRatio } }}
          title={story.title}
          alt={story.title}
          tags={tags}
          to={`/stories/${story.slug.current}`}
          // tagSlot={<Tag invert>Live</Tag>}
          footerSlot={
            <Tag
              to={`/stories/${story.slug.current}`}
              label={story.title}
              sx={{ mt: [3, "14px"] }}
            >
              Read More
            </Tag>
          }
        >
          <Box>
            <Grid columns={[1, 1, numDescriptionColumns]} gap={0}>
              <Box>
                <Box
                  sx={{
                    mt: [1, "4px"],
                    mb: [2, 3],
                    pr: 3,
                    "@media (min-width: 40em)": { pr: "80px" },
                    "@media (min-width: 120em)": { pr: "200px" },
                  }}
                >
                  {story.strapline}
                </Box>
                <Box
                  sx={{
                    pt: [3, "7px"],
                    mb: [0, "10px"],
                    pr: 3,
                    "@media (min-width: 40em)": { pr: "80px" },
                    "@media (min-width: 120em)": { pr: "200px" },
                  }}
                >
                  {story.description}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Card>
      </Flex>
    </Box>
  )
}

export default StoryItem
