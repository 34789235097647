import React from "react" // eslint-disable-line no-unused-vars
import SEO from "../components/navigation/seo"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not found" />
      <Box sx={{ variant: "layout.paddedItem" }}>
        <h1>Sorry we don't have a page for that</h1>
        <Box>
          <p>
            This page doesn't exist, please try searching for what you want to
            find.
          </p>
        </Box>
      </Box>
    </>
  )
}

export default NotFoundPage
