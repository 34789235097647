import React, { useEffect, useRef, useCallback } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import { Link } from "gatsby"
import { RCAEmbed } from "../common/RCAEmbed/RCAEmbed"
import { RCAImage } from "../common/RCAImage"
import { Tag } from "../common/Tag"
import { useLightboxContext } from "../common/Lightbox/LightboxProvider"
import StudentWorkLinks from "./StudentWorkLinks"
import { toLineBreaks } from "../../utils/data"
import TagsFromWork from "../pagelayout/TagsFromWork"
import {
  featuredImageSelector,
  directFilenameImage,
} from "../../fragments/images"
import { MasonryGrid } from "../common/MasonryGrid/MasonryGrid"

const displayImageTitle = title => {
  if (
    title === "." ||
    title === "donotdisplay" ||
    title === "..." ||
    title === "[untitled]"
  ) {
    return false
  } else return true
}

const makeNonLocal = str => {
  return str.startsWith("http") ? str : `http://${str}`
}

const StudentWorkImage = ({
  mediaItem,
  itemWidth = "auto",
  inGrid = false,
  itemPadding = 0,
}) => {
  const { appendMedia, openMedia } = useLightboxContext()
  const lightboxIndex = useRef()
  const imageData =
    mediaItem.mime !== "image/gif"
      ? featuredImageSelector(mediaItem)
      : directFilenameImage(mediaItem.filename)

  useEffect(() => {
    lightboxIndex.current = appendMedia(imageData)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onOpenLightbox = useCallback(() => {
    openMedia(lightboxIndex.current)
  }, [openMedia])

  return (
    <Box sx={{ width: itemWidth, mb: [3, 4], p: itemPadding }}>
      <Box
        onClick={onOpenLightbox}
        sx={{
          position: "relative",
          cursor: "pointer",
          mb: "6px",
          ".lazy-image": {
            width: "100%",
          },
        }}
      >
        <RCAImage image={imageData} alt={mediaItem.title} />
        {mediaItem.url && (
          <Tag
            to={makeNonLocal(mediaItem.url)}
            invert
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate3d(-50%, -50%, 0)",
              fontSize: [3, 5],
              px: [3, "16px"],
              pt: ["6px"],
              pb: ["6px", "8px"],
              boxShadow: "none !important",
            }}
          >
            Launch Project
          </Tag>
        )}
      </Box>
      {mediaItem.title && (
        <Grid columns={inGrid ? [1, 1] : [1, 2]}>
          <Box
            sx={{
              mb: [0, 0],
              variant: "type.imageTitle",
              mb: 0,
              pr: 3,
              "@media (min-width: 40em)": { pr: "80px" },
              "@media (min-width: 120em)": { pr: "200px" },
            }}
          >
            <h3 sx={{ variant: "type.imageTitle" }}>
              {displayImageTitle(mediaItem.title) && mediaItem.title}
              {mediaItem.description && ` — ${mediaItem.description}`}
            </h3>
          </Box>
        </Grid>
      )}
    </Box>
  )
}

const StudentGridItems = ({
  mediaItems,
  itemWidth = "auto",
  itemPadding = 0,
  sx = {},
  inGrid = false,
}) => {
  return (
    <>
      {mediaItems.map((mediaItem, index) => {
        const isImage =
          mediaItem.mime === "image/gif" ||
          mediaItem.mime === "image/jpeg" ||
          mediaItem.mime === "image/png"
        if (mediaItem.imageFile || mediaItem.mime === "image/gif") {
          return (
            <StudentWorkImage
              key={`mediaItem-${index}`}
              mediaItem={mediaItem}
              itemWidth={itemWidth}
              itemPadding={itemPadding}
              inGrid={inGrid}
            />
          )
        }

        if (mediaItem.sourceFilename && !isImage) {
          return (
            <Box
              sx={{ width: itemWidth, mb: [3, 4], p: itemPadding }}
              key={`mediaItem-${index}`}
            >
              <RCAEmbed mediaUrl={mediaItem.sourceFilename} />
              <Grid columns={[1, 2]}>
                <Box sx={{ pt: 2 }}>
                  {mediaItem.title && (
                    <Box sx={{}}>
                      <Box
                        sx={{
                          variant: "type.imageTitle",
                          mb: 0,
                          pr: 3,
                          "@media (min-width: 40em)": { pr: "80px" },
                          "@media (min-width: 120em)": { pr: "200px" },
                          // "@media (min-width: 40em)": { pr: "80px" },
                          // "@media (min-width: 120em)": { pr: "200px" },
                        }}
                      >
                        {displayImageTitle(mediaItem.title) && mediaItem.title}
                        {mediaItem.description && ` — ${mediaItem.description}`}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Box>
          )
        }

        return null
      })}
    </>
  )
}

const StudentWork = ({ work, hash, to }) => {
  return (
    <Box id={hash} sx={{ variant: "layout.paddedItem" }}>
      {work.mediaItems &&
        work.mediaItems.length > 0 &&
        (work.displayMode === "double" ? (
          <MasonryGrid isLarge>
            {({ itemWidth, itemWidthFull, itemPadding }) => (
              <StudentGridItems
                mediaItems={work.mediaItems}
                inGrid
                itemWidth={itemWidth}
                itemPadding={0}
              />
            )}
          </MasonryGrid>
        ) : (
          <Grid columns={1} gap={[0, 0]}>
            <StudentGridItems mediaItems={work.mediaItems} />
          </Grid>
        ))}
      <Grid columns={[1, 2]} sx={{ mt: [3] }}>
        <Box>
          <Box
            sx={{
              pr: 3,
              "@media (min-width: 40em)": { pr: "80px" },
              "@media (min-width: 120em)": { pr: "200px" },
              // pr: 3,
              // "@media (min-width: 40em)": { pr: "80px" },
              // "@media (min-width: 120em)": { pr: "200px" },
              // "p:lastOfType": { mb: 0 },
              mb: 3,
            }}
          >
            {work.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: toLineBreaks(work.description),
                }}
              />
            )}
          </Box>
          {work.medium && work.medium.trim() !== "" && (
            <Box sx={{ mt: 3 }}>
              <h3 sx={{ display: "inline", fontWeight: "bold" }}>Medium: </h3>
              {work.medium}
            </Box>
          )}
          {work.size && work.size.trim() !== "" && (
            <Box>
              <h3 sx={{ display: "inline", fontWeight: "bold" }}>Size: </h3>
              {work.size}
            </Box>
          )}

          {work.links?.length > 0 && (
            <Box sx={{ mt: [2, 3], mb: [7] }}>
              <StudentWorkLinks
                workLinks={work.links}
                eventLink={work.eventLink}
              />
            </Box>
          )}
        </Box>
      </Grid>
      {work.themes?.length > 0 && (
        <Grid columns={[1, 2]} gap={0}>
          <Box sx={{ mb: [2, 3] }}>
            <TagsFromWork work={work} />
          </Box>
        </Grid>
      )}
      <Box sx={{ mb: [4, 7] }}>{/* <Link to={to}>Permalink</Link> */}</Box>
    </Box>
  )
}

export default StudentWork
