import PlaceholderImage from "../images/trans.png"

export const placeholderImageFluid = {
  aspectRatio: 1.6,
  src: PlaceholderImage,
  srcSet: `${PlaceholderImage} 1w`,
  sizes: "(max-width: 1px) 100vw, 1px",
}

export const clickOutside = (el, onClickOutside, validator) => {
  const cb = e => {
    if (validator) {
      if (validator(el, e)) {
        onClickOutside()
      }
    } else if (e.target !== el && !el.contains(e.target)) {
      onClickOutside()
    }
  }

  document.addEventListener("click", cb)
  const unregisterExits = registerExits(onClickOutside)

  return () => {
    unregisterExits()
    document.removeEventListener("click", cb)
  }
}

const KEYS = {
  ESCAPE: 27,
}

export const registerExits = onEscape => {
  const cb = e => {
    if ([KEYS.ESCAPE].includes(e.keyCode)) {
      onEscape()
    }
  }

  document.addEventListener("keyup", cb)
  return () => document.removeEventListener("keyup", cb)
}

export const shuffle = arr => {
  return [...arr].sort(() => Math.random() - 0.5)
}

export const detectTouched = cb => {
  const onTouchStart = () => {
    cb(true)
  }

  const onTouchEnd = () => {
    cb(false)
  }

  document.addEventListener("touchstart", onTouchStart)
  document.addEventListener("touchend", onTouchEnd)

  return () => {
    document.removeEventListener("touchstart", onTouchStart)
    document.removeEventListener("touchend", onTouchEnd)
  }
}

export const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
  !window.MSStream
