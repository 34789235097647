import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Tag } from "../../common/Tag"

const EventBookingDetails = props => {
  const eventBookingDetails = props.eventBookingDetails

  const booklingLinkName = () => {
    if (eventBookingDetails.linkName) return eventBookingDetails.linkName
    else return eventBookingDetails.url
  }

  return (
    <Box>
      <div>
        {eventBookingDetails.url ? (
          <Box sx={{ mb: [3, 4] }}>
            <Tag to={eventBookingDetails.url} target="_blank">
              {booklingLinkName()}
            </Tag>
          </Box>
        ) : (
          <Box sx={{ mb: [3, 4] }}>{eventBookingDetails.linkName}</Box>
        )}
        {eventBookingDetails.description && (
          <Box>{eventBookingDetails.description}</Box>
        )}
      </div>
    </Box>
  )
}

export default EventBookingDetails
