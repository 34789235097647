import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import CollectionItem from "./CollectionItem"
import { shuffle } from "../../utils/common"

const CollectionsBlock = ({ collections, numColumns, orientation }) => {
  return (
    <Grid
      columns={[1, numColumns]}
      gap={[3, 4]}
      sx={{ variant: "layout.paddedItemTLR" }}
    >
      {shuffle(collections).map((collection, index) => {
        return (
          <CollectionItem
            key={index}
            index={index}
            collection={collection}
            aspectRatio={2 / 3}
            collectionsGrid
            sx={{ "div>p": { my: 0 } }}
          />
        )
      })}
    </Grid>
  )
}

export default CollectionsBlock
