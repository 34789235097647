import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Grid, Box, Flex } from "theme-ui"
import { ListItem } from "../components/common/ListItem"
import { ExpandingSection } from "../components/common/ExpandingSection"

import SEO from "../components/navigation/seo"
import BlockContent from "@sanity/block-content-to-react"
import Curator from "../components/pagelayout/collection/Curator"
import SelectedStudent from "../components/pagelayout/collection/SelectedStudent"
import MediaTextItem from "../components/pagelayout/MediaTextItem"
import { MasonryGrid } from "../components/common/MasonryGrid/MasonryGrid"

export const query = graphql`
  query CollectionTemplateQuery($slug: String!) {
    collection: sanityCollection(slug: { current: { eq: $slug } }) {
      collectionName
      slug {
        current
      }
      description
      featuredImage {
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
        altText
        imageDescription
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      selectedGraduates {
        graduateName
        studentImage {
          altText
          imageDescription
          image {
            asset {
              fluid(maxWidth: 960) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        url {
          description
          linkName
          url
        }
        graduateProgramme {
          programmeName
          slug {
            current
          }
        }
      }
      schoolsFocus {
        schoolName
        slug {
          current
        }
      }
      programmesFocus {
        programmeName
        slug {
          current
        }
      }
      curators {
        curatorName
        curatorUrl
        curatorBio
        curatorPortrait {
          altText
          imageDescription
          image {
            asset {
              fluid(maxWidth: 960) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      curatorVideo {
        description
        media {
          mediaUrl
          selectPlatform
          title
        }
      }
      curatorAudio {
        description
        media {
          mediaUrl
          selectPlatform
          title
        }
      }
    }
  }
`

const CollectionTemplate = props => {
  const collection = props.data.collection

  const seoImage = collection.featuredImage?.image?.asset?.fluid?.src
  const seoDescription = collection.description

  return (
    <>
      <SEO
        title={collection.collectionName}
        description={seoDescription}
        image={seoImage}
      />
      <Box>
        <ListItem
          to={`/collections/`}
          icon="arrow-left"
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          bottomBorder={false}
          size="navigation"
          reverse
          justifyContent="flex-end"
        >
          Collections
        </ListItem>
        <Box>
          <ListItem
            leftBorder={false}
            rightBorder={false}
            size="large"
            tagName="h1"
            // sx={{ py: [3, 4] }}
          >
            {collection.collectionName}
          </ListItem>
        </Box>

        <Grid columns={[1, 2]} gap={0}>
          <Box sx={{ variant: "layout.paddedItemLR", mt: [4, 7], mb: [7, 8] }}>
            {/* Body Text */}
            {collection._rawBody && (
              <Box
                sx={{
                  pr: 3,
                  "@media (min-width: 40em)": { pr: "80px" },
                  "@media (min-width: 120em)": { pr: "200px" },
                  variant: "type.indexDescription",
                }}
              >
                <BlockContent blocks={collection._rawBody} sx={{}} />
              </Box>
            )}
          </Box>
        </Grid>

        {/* Featured Students */}
        {collection.selectedGraduates?.length > 0 && (
          <Box sx={{ variant: "layout.paddedItemLR", mb: [7, "76px"] }}>
            {collection.selectedGraduates?.length > 0 && (
              <MasonryGrid isLarge>
                {({ itemWidth }) => (
                  <>
                    {collection.selectedGraduates.map((student, index) => {
                      return (
                        <SelectedStudent
                          key={`selected-student${index}`}
                          index={index}
                          student={student}
                          customSx={{
                            width: itemWidth,
                          }}
                        />
                      )
                    })}
                  </>
                )}
              </MasonryGrid>
            )}
          </Box>
        )}
        {/* Curator Info */}
        {collection.curators?.length > 0 && (
          <Box>
            {collection.curators?.length > 0 && (
              <ExpandingSection title="Curator Biography">
                {collection.curators.map((curator, index) => {
                  return (
                    <Curator
                      key={`curator${index}`}
                      index={index}
                      curator={curator}
                    />
                  )
                })}
              </ExpandingSection>
            )}
          </Box>
        )}
        {/* Video Archive */}
        {collection.curatorVideo && (
          <ExpandingSection title="Curator Video">
            <Box sx={{ variant: "layout.paddedItem", mb: [7, 9] }}>
              <MediaTextItem mediaText={collection.curatorVideo} />
            </Box>
          </ExpandingSection>
        )}

        {/* Audio Archive */}
        {collection.curatorAudio && (
          <ExpandingSection title="Curator Audio">
            <Box sx={{ variant: "layout.paddedItem", mb: [7, 9] }}>
              <MediaTextItem mediaText={collection.curatorAudio} />
            </Box>
          </ExpandingSection>
        )}
      </Box>
    </>
  )
}

export default CollectionTemplate
