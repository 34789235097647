import React, { useContext } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, useThemeUI, Flex, Text } from "theme-ui"
import { useResponsiveValue } from "@theme-ui/match-media"
import { motion, AnimatePresence } from "framer-motion"
import ModeToggles from "./mode/ModeToggles"
import { RCAContext } from "../../context/RCAContext"
import CookieConsent from "react-cookie-consent"

const Footer = () => {
  const { theme } = useThemeUI()
  const { navAnchored, isSearchOpen, searchBarWidth, bodyLocked } = useContext(
    RCAContext,
  )

  const variants = {
    initial: {
      y: theme.layout.footer.height * 2,
    },
    enter: {
      y: 0,
      transition: {
        duration: theme.animation.page.duration,
        delay: theme.animation.page.duration,
        when: "beforeChildren",
      },
    },
    exit: {
      y: theme.layout.footer.height * 2,
      transition: { duration: theme.animation.page.duration },
    },
  }

  const cookiePadding = useResponsiveValue(["12px", "24px", "24px"])
  const cookieTextWidth = useResponsiveValue(["80vw", "80vw", "40vw"])
  return (
    <>
      <AnimatePresence>
        {navAnchored && (
          <motion.footer
            variants={variants}
            initial="initial"
            animate={navAnchored ? "enter" : "exit"}
            exit="exit"
            sx={{
              display: "flex",
              position: "fixed",
              height: `calc(${theme.layout.footer.height[0]}px + env(safe-area-inset-bottom))`,
              "@media (min-width: 40em)": {
                height: `calc(${theme.layout.footer.height[1]}px + env(safe-area-inset-bottom))`,
              },
              width: "100%",
              // left: 0,
              bottom: 0,
              userSelect: "none",
              zIndex: 1000,
              pb: "env(safe-area-inset-bottom)",
              backgroundColor: "background",
              borderStyle: "solid",
              borderColor: "text",
              borderWidth: 0,
              borderTopWidth: theme.sizes.borderWidth,
              // adjust for scroll lock - positioning must be different
              right: bodyLocked && searchBarWidth,
            }}
          >
            <CookieConsent
              enableDeclineButton
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              cookieName="gatsby-gdpr-google-analytics"
              // disableStyles={true}
              style={{
                // height: "20vh",
                background: theme.colors.text,
                margin: 0,
                display: "block",
                alignItems: "center",
                fontSize: "11px",
                zIndex: 20000,
              }}
              contentStyle={{
                margin: 0,
                width: cookieTextWidth,
                padding: cookiePadding,
                display: "block",
              }}
              buttonStyle={{
                background: theme.colors.background,
                color: theme.colors.text,
                margin: `0 ${cookiePadding} ${cookiePadding} ${cookiePadding}`,
                borderWidth: "0px",
                boxShadow: "none",
                "@media (minWidth: 40em)": {
                  borderWidth: "1px",
                  boxShadow: "0 0 0px 0.6px currentColor",
                },
                borderColor: "text",
                borderStyle: "solid",
                borderRadius: theme.sizes.tagRadii,
                textDecoration: "none",
              }}
              declineButtonStyle={{
                background: theme.colors.background,
                color: theme.colors.text,
                margin: `0 ${cookiePadding} ${cookiePadding} ${cookiePadding}`,
                borderWidth: "0px",
                boxShadow: "none",
                "@media (minWidth: 40em)": {
                  borderWidth: "1px",
                  boxShadow: "0 0 0px 0.6px currentColor",
                },
                borderColor: "text",
                borderStyle: "solid",
                borderRadius: theme.sizes.tagRadii,
                textDecoration: "none",
              }}
            >
              We use cookies and similar methods to recognize visitors and
              remember their preferences. We also use them to measure ad
              campaign effectiveness, target ads and analyze site traffic. To
              learn more about these methods, including how to disable them,
              view our{" "}
              <a href="/about/#privacy-policy" style={{ color: "#ffffff" }}>
                Cookie Policy
              </a>
              . By clicking ‘accept,’ you consent to the processing of your data
              by us and third parties using the above methods.
              {/* You can always change your tracker preferences by visiting our Cookie Policy. */}
            </CookieConsent>
            <Flex
              sx={{
                flexBasis: "50%",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRightWidth: theme.sizes.borderWidth,
                borderRightStyle: "solid",
                borderRightColor: "text",
                px: theme => theme.layout.paddedX.px,
                // adjust for scroll lock - above mod makes this too small
                marginLeft: bodyLocked && searchBarWidth,
              }}
            >
              <Text>
                <Link to={"/"}>RCA2020</Link>
              </Text>
            </Flex>
            <Flex
              as="nav"
              sx={{
                variant: "layout.paddedX",
                flexBasis: "50%",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <motion.div
                variants={variants}
                initial="initial"
                animate={isSearchOpen ? "exit" : "enter"}
                exit="exit"
              >
                <ModeToggles />
              </motion.div>
            </Flex>
          </motion.footer>
        )}
      </AnimatePresence>
    </>
  )
}

export default Footer
