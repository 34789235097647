import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
import SEO from "../components/navigation/seo"
// import Video from "../components/atomic/video"
import StudentPage from "../components/student/StudentPage"

export const query = graphql`
  query StudentTemplateQuery($id: String!, $eventSlug: String!) {
    student: mongodbRcaapiSubmissions(id: { eq: $id }) {
      id
      profileImageUri
      statements {
        text
        type
        imageFile {
          ...FeaturedImage
        }
      }
      user {
        firstName
        lastName
        fullName
      }
      slug
      displayName
      school
      programme
      programmeCode
      courseCode
      contacts {
        identifier
        notes
        type
      }
      links {
        title
        url
        description
      }
      sponsors {
        url
        title
        description
      }
      events {
        description
        title
        url
      }
      works {
        _id
        slug
        themes {
          text
          slug
        }
        selected
        title
        type
        year
        medium
        size
        description
        displayMode
        links {
          description
          title
          url
        }
        eventLink
        mediaItems {
          _id
          filename
          sourceFilename
          title
          type
          mime
          description
          title
          url
          imageFile {
            ...FeaturedImage
          }
        }
      }
    }
    programmes: allSanityProgramme {
      edges {
        node {
          id
          programmeName
          programmeCode
          slug {
            current
          }
        }
      }
    }
    events: allSanityEvent(
      filter: {
        artistGraduates: { elemMatch: { url: { url: { regex: $eventSlug } } } }
      }
    ) {
      edges {
        node {
          eventName
          eventDate
          eventType
          strapline
          location
          slug {
            current
          }
        }
      }
    }
  }
`

const StudentTemplate = ({ data, location, pageContext }) => {
  const student = data && data.student
  const programmes = data && data.programmes
  const events = data && data.events

  // get the current pathway/platform
  let programme
  if (student.courseCode && student.courseCode !== "") {
    programme = programmes.edges.find(({ node }) => {
      return node.programmeCode === student.courseCode
    })
  } else {
    programme =
      student.programmeCode && student.programmeCode !== ""
        ? programmes.edges.find(({ node }) => {
            return node.programmeCode === student.programmeCode
          })
        : null
  }

  const studentAbout = student.statements.find(
    statement => statement.type === "biography",
  )
  const seoImage = studentAbout?.imageFile?.childCloudinaryAsset?.fluid?.src
  const seoDescription = studentAbout?.text
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/\&nbsp;/g, "")

  return (
    <>
      <SEO
        title={student.displayName}
        image={seoImage}
        description={seoDescription}
      />
      <StudentPage
        student={student}
        hash={location.hash}
        programme={programme?.node}
        pagination={pageContext.pagination}
        events={events.edges}
      />
    </>
  )
}

export default StudentTemplate
