import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import { RCAImage } from "../../common/RCAImage"

const StoryImageBlock = ({ imageBlock }) => {
  const numberImages = imageBlock.length

  let gridColums
  if (numberImages > 1) gridColums = 2

  return (
    <Box>
      <Grid columns={[1, gridColums]} gap={[3, 4]}>
        {imageBlock.map((imageItem, index) => {
          return (
            <Box key={`story-image-block-item-${index}`}>
              <RCAImage
                image={imageItem?.image?.asset?.fluid}
                alt={imageItem?.image?.altText}
              />
              <Box sx={{ variant: "type.imageTitle", pt: [3, 3], mb: [3, 0] }}>
                {imageItem.imageDescription}
              </Box>
            </Box>
          )
        })}
      </Grid>
    </Box>
  )
}

export default StoryImageBlock
