import React from "react"

import PropTypes from "prop-types"
import { Box } from "theme-ui"

import { Icon, iconKeys } from "./Icon"

const RotateIcon = ({
  name,
  rotate = 45,
  startRotate = 0,
  iconProps = {},
  toggle = false,
}) => {
  return (
    <Box
      as="span"
      sx={{
        transform: toggle
          ? `rotate(${rotate}deg)`
          : `rotate(${startRotate}deg)`,
        transition: "transform 0.25s ease-in-out",
      }}
    >
      <Icon name={name} {...iconProps} />
    </Box>
  )
}

RotateIcon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  iconProps: PropTypes.object,
  rotate: PropTypes.number,
  startRotate: PropTypes.number,
  toggle: PropTypes.bool,
}

export { RotateIcon }
