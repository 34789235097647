import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import ModeButton from "./ModeButton"
const ModeToggles = () => {
  return (
    <Flex
      sx={{
        lineHeight: 0,
        mt: "1px",
      }}
    >
      <Box sx={{ ml: 2 }}>
        <ModeButton />
      </Box>
    </Flex>
  )
}

export default ModeToggles
