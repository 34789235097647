import React, { // eslint-disable-line no-unused-vars
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react"
import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Grid, useThemeUI } from "theme-ui"
import { alpha } from "@theme-ui/color"
import SchoolMenuItem from "./SchoolMenuItem"
import { ListItem } from "../../common/ListItem"
import { List } from "../../common/List"
import { Collapsible } from "../../common/Collapsible"
import { PlusIcon } from "../../common/PlusIcon"
import { RCAContext, MENUS } from "../../../context/RCAContext"
import { clickOutside } from "../../../utils/common"

const STATIC_LINKS = [
  {
    to: "/students",
    label: "Students",
  },
  {
    to: "/collections",
    label: "Collections",
  },
  {
    to: "/events",
    label: "Events",
  },
  {
    to: "/stories",
    label: "Stories",
  },
  {
    to: "/tags",
    label: "Tags",
  },
]

const Menu = ({ onReportRef }) => {
  const { theme } = useThemeUI()
  const {
    isNavOpen,
    closeMenu,
    currentMenuRef,
    searchBarWidth,
    bodyLocked,
  } = useContext(RCAContext)
  const listRef = useRef(null)

  const data = useStaticQuery(graphql`
    query SchoolsMenuQuery {
      allSanitySchool(sort: { order: ASC, fields: schoolName }) {
        nodes {
          schoolName
          slug {
            current
          }
          programmes {
            programmeName
            headProgramme
            researchProgramme
            slug {
              current
            }
            programmePathways {
              programmeName
              slug {
                current
              }
            }
            programmePlatforms {
              programmeName
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)

  const [schoolsSubmenu, setSchoolsSubmenu] = useState(false) // Need to determine start state if sub route

  const schools = data.allSanitySchool.nodes

  useEffect(() => {
    if (isNavOpen && listRef.current) {
      return clickOutside(listRef.current, () => {
        if (currentMenuRef.current !== MENUS.SEARCH) {
          closeMenu()
        }
      })
    }
  }, [isNavOpen, closeMenu, currentMenuRef])

  const onRef = useCallback(
    ref => {
      listRef.current = ref

      onReportRef(ref)
    },
    [onReportRef],
  )

  return (
    <Grid
      as="nav"
      gap={0}
      columns={[1, 2]}
      sx={{
        height: "100vh",
        backgroundColor: alpha("background", 0.6),
        marginRight: bodyLocked && searchBarWidth,
      }}
    >
      <List
        ref={onRef}
        sx={{
          borderRightColor: "text",
          borderRightStyle: "solid",
          borderRightWidth: theme => theme.sizes.borderWidth,
          backgroundColor: "background",
          pb: theme.layout.footer.height,
          pt: theme.layout.header.height,
          height: "100vh",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <li>
          <ListItem
            to="/"
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
          >
            Home
          </ListItem>
        </li>
        <li>
          <ListItem
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
            onClick={() => setSchoolsSubmenu(!schoolsSubmenu)}
            slot={<PlusIcon toggle={schoolsSubmenu} name="plus" />}
          >
            School/Programme
          </ListItem>

          <List>
            <Collapsible duration={250} open={schoolsSubmenu}>
              {schools.map((school, i) => (
                <SchoolMenuItem key={school.slug.current} school={school} />
              ))}
            </Collapsible>
          </List>
        </li>
        {STATIC_LINKS.map(item => (
          <li key={item.to}>
            <ListItem
              to={item.to}
              rightBorder={false}
              leftBorder={false}
              topBorder={false}
              size="navigation"
            >
              {item.label}
            </ListItem>
          </li>
        ))}
      </List>
    </Grid>
  )
}

export default Menu
