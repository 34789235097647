import React, { useMemo } from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
import { useMatch } from "@reach/router"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import SEO from "../components/navigation/seo"
import { ListItem } from "../components/common/ListItem"
import TagsFromStudents from "../components/pagelayout/TagsFromStudents"
import { IndividualTag } from "../components/pagelayout/IndividualTag"

export const query = graphql`
  query TagsQuery {
    tags: allTag(
      sort: { fields: slug, order: ASC }
      filter: { total: { gt: 1 } }
    ) {
      edges {
        node {
          id
          slug
          text
          total
          displayText
        }
      }
    }
  }
`

const Tags = ({ data }) => {
  const match = useMatch("/tags/:tagName")

  if (match && match.tagName) {
    return <IndividualTag tagName={match.tagName} />
  }

  const sortedTags = [...data.tags.edges].sort((a, b) => {
    if (a.node.displayText < b.node.displayText) {
      return -1
    }
    if (a.node.displayText > b.node.displayText) {
      return 1
    }
    return 0
  })

  return (
    <Box>
      <SEO title="Tags" description="RCA Tags" />
      <ListItem
        topBorder={false}
        leftBorder={false}
        rightBorder={false}
        size="large"
        tagName="h1"
      >
        Tags
      </ListItem>
      <Box sx={{ mb: [6, 7] }}>
        <TagsFromStudents tags={sortedTags} />
      </Box>
    </Box>
  )
}

export default Tags
