import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { Tag } from "../common/Tag"

const TagsFromWork = ({ work }) => {
  const allTags = work.themes
    .map(theme => {
      if (theme.slug && theme.text) {
        return theme
      }
      return null
    })
    .flat(2)
    .filter(function(el) {
      return el != null
    })
    .reduce(
      (items, item) =>
        items.find(x => x.slug === item.slug) ? [...items] : [...items, item],
      [],
    )
    .sort((a, b) => a.text.localeCompare(b.text))
  return (
    <Flex
      sx={{
        flexWrap: "wrap",
        alignItems: "flex-start",
        pb: [0, 3],
      }}
    >
      {allTags.map(tag => (
        <Tag
          key={tag.slug}
          to={`/tags/${tag.slug}`}
          size="normal"
          squared
          sx={{ mr: 2, mb: 2, textTransform: "capitalize" }}
        >
          {tag.text}
        </Tag>
      ))}
    </Flex>
  )
}

export default TagsFromWork
