import React, { createRef, useLayoutEffect, useEffect } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid, useThemeUI, Flex } from "theme-ui"
import { useResponsiveValue } from "@theme-ui/match-media"
import { RCAImage } from "../common/RCAImage"
import { ExpandingSection } from "../common/ExpandingSection"
import StudentWork from "./StudentWork"
import { ListItem } from "../common/ListItem"
import StudentAbout from "./StudentAbout"
import StudentStatement from "./StudentStatement"
import { LightboxContainer } from "../common/Lightbox/LightboxProvider"
import { checkIfSponsorsEmpty } from "../../utils/data"
import { featuredImageSelector } from "../../fragments/images"
import EventsBlock from "../pagelayout/EventsBlock"
const StudentPage = ({ student, programme, hash, pagination, events }) => {
  // get the about and statement objects
  const studentAbout = student.statements.find(
    statement => statement.type === "biography",
  )
  const studentStatement = student.statements.find(
    statement => statement.type === "where_am_i",
  )

  const checkIfStudentStatementExists = statement => {
    if (statement) {
      if (statement.text) {
        if (statement.text.trim() !== "" || statement.imageFile !== null) {
          return true
        } else {
          return false
        }
      } else return false
    } else return false
  }

  // create an array of work Refs
  const workRefs = student.works.reduce((acc, value, index) => {
    acc[value.slug] = createRef()
    return acc
  }, {})

  const { theme } = useThemeUI()
  const scrollOffset =
    useResponsiveValue(theme.layout.header.height) -
    useResponsiveValue(theme.sizes.borderWidth)
  // const scrollOffset = 0
  // // Moves users to location on load
  useEffect(() => {
    if (
      hash &&
      workRefs[hash.substring(1)]?.current &&
      hash.substring(1).trim() // Changed this to satisfy linter, hopefully still works
    ) {
      const timer = setTimeout(() => {
        window.scrollTo({
          top: workRefs[hash.substring(1)].current.offsetTop - scrollOffset,
          behavior: "smooth",
        })
      }, 1400)
      return () => {
        clearTimeout(timer)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LightboxContainer>
      <Box>
        {/* Replace with back to Programme */}
        {programme && (
          <ListItem
            to={`/programmes/${programme?.slug.current}`}
            icon="arrow-left"
            topBorder={false}
            leftBorder={false}
            rightBorder={false}
            bottomBorder={false}
            size="navigation"
            reverse
            justifyContent="flex-end"
          >
            {programme?.programmeName}
          </ListItem>
        )}
        <ListItem
          topBorder={!!programme}
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h1"
          // sx={{ py: [3, 4] }}
        >
          {student.displayName}
        </ListItem>

        <Box sx={{ variant: "layout.paddedItem" }}>
          <RCAImage
            image={featuredImageSelector(studentAbout) || undefined}
            alt={student.user.displayName}
            imageStyles={{
              style: {
                maxHeight: "80vh",
              },
            }}
          />
        </Box>

        {/* About Block */}
        <ExpandingSection title="About">
          <StudentAbout
            student={student}
            studentAbout={studentAbout}
            programme={programme}
          />
        </ExpandingSection>

        {/* Statement Block */}
        {checkIfStudentStatementExists(studentStatement) && (
          <ExpandingSection title="Statement">
            <StudentStatement
              student={student}
              studentStatement={studentStatement}
            />
          </ExpandingSection>
        )}

        {/* Works */}
        {student.works &&
          student.works.map((work, index) => {
            return (
              <div
                // id={work.slug}
                key={`${student.slug}/${work.slug}/${index}`}
                ref={workRefs[work.slug]}
              >
                <ExpandingSection
                  title={work.title && work.title}
                  link={`/students/${student.slug}/#${work.slug}`}
                >
                  <StudentWork
                    work={work}
                    hash={work.slug}
                    to={`/students/${student.slug}/#${work.slug}`}
                  />
                </ExpandingSection>
              </div>
            )
          })}

        {/* Sponsors Block */}
        {checkIfSponsorsEmpty(student.sponsors) && (
          <ExpandingSection title="Sponsors">
            <Grid
              columns={[1, 2]}
              gap={0}
              sx={{ variant: "layout.paddedItem" }}
            >
              <Box>
                {student.sponsors.map((sponsor, index) => {
                  return (
                    <Box key={`sponsor-block-${index}`} sx={{ mb: [3, 4] }}>
                      {sponsor.title && (
                        <Box sx={{ mb: [2, 3] }}>
                          <h3>{sponsor.title}</h3>
                        </Box>
                      )}

                      {sponsor.description && (
                        <Box sx={{ mb: [2, 3] }}>{sponsor.description}</Box>
                      )}
                      {sponsor.url && (
                        <Box sx={{ mb: [3, 4] }}>
                          <h3 sx={{ display: "inline" }}>Website: </h3>
                          <a
                            href={sponsor.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {sponsor.url}
                          </a>
                        </Box>
                      )}
                    </Box>
                  )
                })}
              </Box>
            </Grid>
          </ExpandingSection>
        )}
        {/* Events */}
        {events?.length > 0 && (
          <Box>
            <ExpandingSection title="Events">
              <EventsBlock events={events} />
            </ExpandingSection>
          </Box>
        )}
      </Box>
      {pagination && (
        <Flex>
          <ListItem
            to={`/students/${pagination.previous}`}
            icon="arrow-left"
            leftBorder={false}
            bottomBorder={false}
            reverse
            justifyContent="flex-end"
            size="navigation"
          >
            Previous Student
          </ListItem>
          <ListItem
            to={`/students/${pagination.next}`}
            icon="arrow-right"
            rightBorder={false}
            leftBorder={false}
            bottomBorder={false}
            justifyContent="flex-end"
            size="navigation"
          >
            Next Student
          </ListItem>
        </Flex>
      )}
    </LightboxContainer>
  )
}

export default StudentPage
