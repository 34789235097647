import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import MediaTextItem from "./MediaTextItem"

const MediaTextBlock = props => {
  const mediaText = props.mediaItems

  return (
    <Box>
      {mediaText.map((item, index) => {
        return <MediaTextItem key={`media-text-${index}`} mediaText={item} />
      })}
    </Box>
  )
}

export default MediaTextBlock
