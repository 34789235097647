import React, { useState, useCallback, useMemo } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, useColorMode, Button, useThemeUI } from "theme-ui"

const modes = ["light", "blue", "dark"]

const ModeButton = () => {
  const { theme } = useThemeUI()
  const [colorMode, setColorMode] = useColorMode()
  const currentColor = useMemo(() => {
    return colorMode === "default" ? "light" : colorMode
  }, [colorMode])

  const currentColorIndex = modes.indexOf(currentColor)
  const nextColour = (currentColorIndex + 1) % modes.length
  const nextColourString = modes[nextColour]
  const nextColourObject = theme.colors.modes[nextColourString]

  const modeButtonClick = useCallback(() => {
    const index = modes.indexOf(currentColor)
    const next = modes[(index + 1) % modes.length]
    setColorMode(next)
  }, [currentColor, setColorMode])

  return (
    <Button
      sx={{
        fontSize: "0px",
        borderRadius: "100%",
        height: theme.layout.toggleButtons.width,
        width: theme.layout.toggleButtons.height,
        p: 0,
        m: 0,
        overflow: "hidden",
        outline: "none",
        cursor: "pointer",
        border: 0,
        backgroundColor: nextColourObject.background,
        "&:hover, &:active": {
          color: nextColourObject.text,
          backgroundColor: nextColourObject.background,
        },
      }}
      onClick={modeButtonClick}
    >
      {nextColourString}
    </Button>
  )
}

export default ModeButton
