import React, { useState, useRef, useCallback, useEffect } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { connectSearchBox } from "react-instantsearch-dom"
/** @jsx jsx */
import { jsx, Input } from "theme-ui"
import { Icon } from "../common/Icon"
import { IconButton } from "../common/IconButton"

const SearchInput = ({
  refine,
  delay = 400,
  focused,
  onFocus,
  onClose,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [queryText, setQueryText] = useState("")

  const timerRef = useRef(null)

  const queryHook = useCallback(
    (query, time = false) => {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(
        () => {
          refine(query)
        },
        !!time ? time : delay,
      )
    },
    [delay, refine],
  )

  const onChange = useCallback(
    e => {
      setQueryText(e.target.value)
      queryHook(e.target.value)
    },
    [queryHook],
  )

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      queryHook(queryText, 0)
    },
    [queryText, queryHook],
  )

  useEffect(() => {
    if (!focused) {
      // Make sure we revoke focus from the input
      inputRef.current.blur()

      // If the input is already empty dont do cleanup
      if (!inputRef.current.value.trim()) {
        return
      }

      // This timer gives a chance to the panel to disappear before
      // removing the results
      const timer = setTimeout(() => {
        inputRef.current.value = ""
        setQueryText("")
        queryHook("", 0)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [focused, queryHook])

  return (
    <form
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
        backgroundColor: focused ? "text" : "background",
        color: focused ? "background" : "text",
        border: "none",
        borderBottomColor: focused ? "background" : "text",
        borderBottomStyle: "solid",
        borderBottomWidth: theme => theme.sizes.borderWidth,
        px: [3, 4],
        height: "100%",
      }}
      onSubmit={onSubmit}
    >
      <Input
        type="search"
        aria-label={"Search"}
        onChange={onChange}
        ref={inputRef}
        autoComplete="off"
        onFocus={onFocus}
        {...rest}
        sx={{
          variant: "type.navigation",
          textDecoration: "none",
          width: "100%",
          outline: "none",
          // fontSize: [2, 3],
          fontWeight: "body",
          borderRadius: 0,
          border: "none",
          flexGrow: 1,
          pl: 0,
          pr: [3, 4],
          py: 1,
          "::placeholder": {
            color: focused ? "background" : "text",
            // fontSize: [2, 3],
            // fontFamily: "body",
            // fontWeight: "body",
            variant: "type.navigation",
          },
          "::-webkit-search-decoration, ::-webkit-search-cancel-button, ::-webkit-search-results-button, ::-webkit-search-results-decoration": {
            WebkitAppearance: "none",
          },
        }}
      />
      {!focused ? (
        <Icon name="search" />
      ) : (
        <IconButton
          sx={{
            color: "background",
            "&:hover": { color: "background", opacity: 0.5 },
          }}
          icon="x"
          onClick={onClose}
        />
      )}
    </form>
  )
}

SearchInput.propTypes = {
  delay: PropTypes.number,
  onFocus: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  focused: PropTypes.bool,
}

export default connectSearchBox(SearchInput)
