import React from "react" // eslint-disable-line no-unused-vars

/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const ProgrammesFocus = ({ programmesFocus }) => {
  let programmesNames = ""
  let i = ""

  for (i = 0; i < programmesFocus.length; i++) {
    if (i <= programmesFocus.length - 2) {
      programmesNames += programmesFocus[i].programmeName + ", "
    } else programmesNames += programmesFocus[i].programmeName
  }

  return (
    <>
      {programmesFocus && (
        <Box sx={{ display: "inline" }}>
          <span>{programmesNames}</span>
        </Box>
      )}
    </>
  )
}

export default ProgrammesFocus
