import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import capitalize from "capitalize"
import { Tag } from "../common/Tag"

export const studentsToTags = (students, limit) => {
  const tags = students.edges.map(({ node: student }) => student.tags).flat()

  const { items, counter } = tags.reduce(
    (obj, item) => {
      if (!item) {
        return obj
      }

      const curr = obj.items.find(x => x.slug === item.slug)

      if (curr) {
        obj.counter[item.slug] = obj.counter[item.slug]
          ? obj.counter[item.slug] + 1
          : 1
      }

      return curr
        ? {
            ...obj,
            items: [...obj.items],
          }
        : {
            ...obj,
            items: [...obj.items, item],
          }
    },
    { items: [], counter: {} },
  )

  if (items.length < 50) limit = 0
  if (items.length >= 50 && items.length < 300) limit = 1

  const limiter = currItems => {
    if (!limit) {
      return currItems
    }
    return currItems
      .filter(item => {
        return counter[item.slug] > limit
      })
      .sort((a, b) => {
        return counter[b.slug] - counter[a.slug]
      })
  }

  return limiter(items)
    .map(theme => ({
      node: {
        ...theme,
        displayText: capitalize.words(theme.text),
      },
    }))
    .sort((a, b) => a.node.text.localeCompare(b.node.text))
}

const TagsFromStudents = ({ tags, hasMore }) => {
  return (
    <Flex
      sx={{
        variant: "layout.paddedItemTLR",
        flexWrap: "wrap",
        alignItems: "flex-start",
        mb: "-8px",
      }}
    >
      {tags.map(({ node: tag }) => (
        <Tag
          key={tag.slug}
          to={`/tags/${tag.slug}`}
          size="normal"
          squared
          sx={{ mr: 2, mb: 3 }}
        >
          {tag.displayText || tag.text}
        </Tag>
      ))}
      {hasMore && (
        <Tag
          invert
          to="/tags"
          label="Load more tags"
          size="normal"
          icon="plus"
          squared
        >
          Show More
        </Tag>
      )}
    </Flex>
  )
}

export default TagsFromStudents
