import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Grid, Box, Flex } from "theme-ui"
import { RCAImage } from "../common/RCAImage"
import { EventCard } from "./EventCard"
import { Link } from "gatsby"

const EventFeature = ({ featuredEvent }) => {
  return (
    <Grid columns={[1, 2]} gap={0} sx={{ variant: "layout.bottomBorder" }}>
      <Box
        sx={{
          variant: "layout.rightBorder",
        }}
      >
        {featuredEvent?.featuredImage?.image && (
          <Box sx={{ variant: "layout.paddedItemTLR", mb: [0, 4] }}>
            <Link to={`/events/${featuredEvent?.slug.current}`}>
              <RCAImage
                image={featuredEvent.featuredImage.image?.asset?.fluid}
                alt={
                  featuredEvent.featuredImage?.altText
                    ? featuredEvent.featuredImage?.altText
                    : "Featured Event"
                }
                fluidOverrides={{ aspectRatio: 16 / 9 }}
              />
            </Link>
          </Box>
        )}
      </Box>
      <Box sx={{ variant: "layout.paddedItem" }}>
        <Flex backgroundColor="background" sx={{ height: "100%" }}>
          <EventCard contentSx={{ mb: [7, 9] }} {...featuredEvent} />
        </Flex>
      </Box>
    </Grid>
  )
}

export default EventFeature
