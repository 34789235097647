import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react"
import PropTypes from "prop-types"

import { Box } from "theme-ui"
// import { motion, AnimatePresence } from "framer-motion"

const states = {
  closed: {
    height: 0,
  },
  opened: {
    height: "auto",
  },
}

const setStylesOnElement = function(element, styles) {
  Object.assign(element.style, styles)
}

const Collapsible = ({
  children,
  open = false,
  duration = 500,
  timingFunction = "ease-in-out",
  onTransitionStart,
  onTransitionEnd,
  sx = {},
}) => {
  const wrapperRef = useRef()
  const timerRef = useRef()

  const [mountContent, setMountContent] = useState(open)
  const [internalOpened, setInternalOpened] = useState(open)

  const transitionState = useCallback(
    async (el, direction) => {
      if (!el) {
        return
      }

      if (onTransitionStart) {
        onTransitionStart(direction)
      }

      const current = direction ? states.closed : states.opened
      const target = direction ? states.opened : states.closed

      setStylesOnElement(el, target)
      const last = el.getBoundingClientRect()

      setStylesOnElement(el, current)
      const first = el.getBoundingClientRect()

      setInternalOpened(direction)

      setStylesOnElement(el, {
        height: `${first.height}px`,
      })

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setStylesOnElement(el, {
            transition: `height ${duration}ms ${timingFunction}`,
          })

          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              setStylesOnElement(el, {
                height: `${last.height}px`,
              })

              timerRef.current = setTimeout(() => {
                if (!direction) {
                  setMountContent(false)
                }

                el.removeAttribute("style")

                if (onTransitionEnd) {
                  onTransitionEnd(open)
                }
              }, duration)
            })
          })
        })
      })
    },
    [duration, onTransitionEnd, onTransitionStart, open, timingFunction],
  )

  const setRef = useCallback(
    ref => {
      if (ref) {
        wrapperRef.current = ref
        transitionState(wrapperRef.current, open)
      }
    },
    [open, transitionState],
  )

  useLayoutEffect(() => {
    if (open && !internalOpened) {
      setMountContent(true)
    }

    if (!open && internalOpened && wrapperRef.current) {
      transitionState(wrapperRef.current, open)
    }
  }, [open, internalOpened, transitionState])

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  if (!mountContent) {
    return null
  }

  return (
    <Box
      ref={setRef}
      sx={{
        overflow: "hidden",
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

Collapsible.propTypes = {
  children: PropTypes.node,

  // Whether or not the collapsed element is open
  open: PropTypes.bool,

  onAnimationComplete: PropTypes.func,
  onAnimationStart: PropTypes.func,
}

export { Collapsible }
