import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import SEO from "../components/navigation/seo"
import { ListItem } from "../components/common/ListItem"
import { RCAImage } from "../components/common/RCAImage"
import StoryPageBlocks from "../components/pagelayout/story/StoryPageBlocks"
import { ExpandingSection } from "../components/common/ExpandingSection"
import BlockContent from "@sanity/block-content-to-react"
import ExternalLinks from "../components/commonblocks/ExternalLinks"
import StudentsBlock from "../components/pagelayout/StudentsBlock"
import EventsBlock from "../components/pagelayout/EventsBlock"

export const query = graphql`
  query StoryTemplateQuery($slug: String!) {
    story: sanityStory(slug: { current: { eq: $slug } }) {
      title
      strapline
      description
      slug {
        current
      }
      featuredImage {
        altText
        imageDescription
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      pageBlocks {
        _rawTextArea(resolveReferences: { maxDepth: 10 })
        imageGroup {
          imageItemBlock {
            altText
            imageDescription
            image {
              asset {
                fluid(maxWidth: 1920) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        hostedMedia {
          mediaUrl
          selectPlatform
          title
        }
        _rawTextFootnote(resolveReferences: { maxDepth: 10 })
      }
      aboutTitle
      _rawAboutStory
      externalLinks {
        description
        linkName
        url
      }
      artistGraduates {
        graduateName
        url {
          url
          linkName
          description
        }
        studentImage {
          altText
          imageDescription
          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        graduateProgramme {
          programmeName
          slug {
            current
          }
        }
      }
      relatedEvents {
        eventName
        eventDate
        eventType
        strapline
        location
        slug {
          current
        }
      }
    }
  }
`

const StoryTemplate = ({ data }) => {
  const story = data.story
  const storyImage = story.featuredImage

  const seoImage = story.featuredImage?.image?.asset?.fluid?.src
  const seoDescription = story.description

  return (
    <>
      <SEO
        title={story.title}
        // description={seoDescription}
        image={seoImage}
      />
      <Box>
        {/* Back to Stories */}
        <Box>
          <ListItem
            to={`/stories`}
            icon="arrow-left"
            leftBorder={false}
            rightBorder={false}
            bottomBorder={false}
            topBorder={false}
            size="navigation"
            reverse
            justifyContent="flex-end"
          >
            Stories
          </ListItem>
        </Box>
        {/* Title */}
        <Box sx={{ variant: "layout.borderTopBottom" }}>
          <Box
            sx={{
              variant: "layout.paddedItemLR",
              pt: [2, 3],
              pb: [3, 4],
              width: ["100%", "70%"],
            }}
          >
            <Box sx={{ fontSize: ["32px", "72px"] }}>{story.title}</Box>
          </Box>
        </Box>
        {/* Featured Image */}
        <Box sx={{ variant: "layout.bottomBorder" }}>
          <Box sx={{ variant: "layout.paddedItem" }}>
            <RCAImage
              image={storyImage?.image?.asset?.fluid}
              alt={storyImage?.altText}
              fluidOverrides={{ aspectRatio: 16 / 9 }}
            />
          </Box>
        </Box>
        {/* Page Blocks */}
        {story.pageBlocks && (
          <Box>
            <StoryPageBlocks pageBlocks={story.pageBlocks} />
          </Box>
        )}
        {/* About Title and Text */}
        {story._rawAboutStory?.length > 0 && (
          <Box>
            <ExpandingSection title={story.aboutTitle}>
              <Grid columns={[1, 2]} gap={0}>
                <Box sx={{ variant: "layout.paddedItemTLR", pb: [7, 7] }}>
                  <BlockContent blocks={story._rawAboutStory} />
                  <ExternalLinks externalLinks={story.externalLinks} />
                </Box>
              </Grid>
            </ExpandingSection>
          </Box>
        )}

        {/* Students */}
        {story.relatedEvents?.length > 0 && (
          <Box>
            <ExpandingSection title="Students">
              <Box sx={{ pb: [7, 8] }}>
                <StudentsBlock
                  students={story.artistGraduates}
                  numColumns={3}
                />
              </Box>
            </ExpandingSection>
          </Box>
        )}
        {/* Events */}
        {story.relatedEvents?.length > 0 && (
          <Box>
            <ExpandingSection title="Events">
              <EventsBlock events={story.relatedEvents} />
            </ExpandingSection>
          </Box>
        )}
      </Box>
    </>
  )
}

export default StoryTemplate
