import React, {
  useContext,
  createContext,
  useReducer,
  useRef,
  useEffect,
} from "react"
import loadable from "@loadable/component"

import { LightboxWrapper } from "./LightboxWrapper"
import { registerExits } from "../../../utils/common"
import { RCAContext } from "../../../context/RCAContext"

const LightboxLoadable = loadable(() =>
  import("./Lightbox").then(module => module.Lightbox),
)

const initialState = {
  open: false,
  mediaIndex: -1,
  media: [],
  openMedia: () => {},
  closeLightbox: () => {},
  setMediaIndex: () => {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MEDIA":
      return {
        ...state,
        open: true,
        mediaIndex: action.index,
      }
    case "SET_INDEX":
      return {
        ...state,
        mediaIndex: action.index,
      }
    case "SET_MEDIA":
      return {
        ...state,
        media: action.media.slice(),
      }
    case "APPEND_MEDIA": {
      const media = state.media.slice()

      if (!media.find(item => Object.is(item, action.item))) {
        return {
          ...state,
          media: [
            ...media,
            typeof action.item === "string" ? action.item : { ...action.item },
          ],
        }
      }

      return state
    }
    case "CLOSE_LIGHTBOX":
      return {
        ...state,
        open: false,
      }
    default:
      return state
  }
}

const LightboxContext = createContext({ ...initialState })

const LightboxContainer = ({ children, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const idRef = useRef(0)
  const { setBodyLocked } = useContext(RCAContext)

  const openMedia = index => {
    dispatch({ type: "OPEN_MEDIA", index })
  }

  const closeLightbox = () => {
    dispatch({ type: "CLOSE_LIGHTBOX" })
  }

  const setMediaIndex = index => {
    dispatch({ type: "SET_INDEX", index })
  }

  const appendMedia = item => {
    const insertIdx = idRef.current
    dispatch({ type: "APPEND_MEDIA", item })
    idRef.current++
    return insertIdx
  }

  const setMedia = media => {
    dispatch({ type: "SET_MEDIA", media })
  }

  useEffect(() => {
    if (state.open) {
      return registerExits(() => {
        closeLightbox()
      })
    }

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setBodyLocked(state.open)
      })
    })
  }, [state.open, setBodyLocked])

  const value = {
    ...state,
    openMedia,
    closeLightbox,
    setMediaIndex,
    appendMedia,
    setMedia,
  }

  return (
    <LightboxContext.Provider value={value}>
      {children}

      <LightboxWrapper open={state.open} onClose={closeLightbox}>
        <LightboxLoadable onClose={closeLightbox} />
      </LightboxWrapper>
    </LightboxContext.Provider>
  )
}

const useLightboxContext = () => useContext(LightboxContext)

export { LightboxContainer, useLightboxContext }
