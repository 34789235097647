import React, { useState, useCallback } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { Collapsible } from "../common/Collapsible"
import { ListItem } from "../common/ListItem"
import { PlusIcon } from "./PlusIcon"

const ExpandingSection = ({
  title,
  children,
  initialExpanded = true,
  expandable = true,
  top = true,
}) => {
  const [expandToggle, setExpandToggle] = useState(initialExpanded)

  const onToggle = useCallback(() => {
    if (expandable) {
      setExpandToggle(!expandToggle)
    }
  }, [expandToggle, expandable])

  return (
    <Box sx={{ marginBottom: ["-1px", "-2px"] }}>
      <ListItem
        size="large"
        onClick={onToggle}
        slot={
          <PlusIcon
            customSx={{
              ml: "12px",
            }}
            toggle={expandToggle}
            iconProps={{ size: "large" }}
          />
        }
        topBorder={top}
        rightBorder={false}
        leftBorder={false}
        tagName="h2"
        sx={{ py: [3, 3] }}
      >
        <Flex sx={{ alignItems: "center" }}>{title}</Flex>
      </ListItem>
      <Collapsible open={expandToggle}>{children}</Collapsible>
    </Box>
  )
}

export { ExpandingSection }
