import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"

/** @jsx jsx */
import { jsx, Flex, Heading } from "theme-ui"
import Markdown from "react-markdown"

import { Tag } from "./Tag"
import { RCAImage } from "./RCAImage"
import { Link } from "gatsby"

const CardLinkWrapper = ({ to, children, noTabIndex = false, sx = {} }) => {
  if (!to) {
    return children
  }

  if (to.startsWith("http")) {
    return (
      <a
        href={to}
        sx={{ display: "block", textDecoration: "none", ...sx }}
        target="_blank"
        rel="noreferrer noopener"
        tabIndex={noTabIndex ? -1 : 0}
      >
        {children}
      </a>
    )
  }

  return (
    <Link
      to={to}
      sx={{ display: "block", textDecoration: "none", outline: "none", ...sx }}
      tabIndex={noTabIndex ? -1 : 0}
    >
      {children}
    </Link>
  )
}

const Card = ({
  tags = [],
  tagSlot = null,
  footerSlot = null,
  title,
  children,
  image,
  alt,
  to,
  imageProps = {},
  sx = {},
  contentSx = {},
  footerSx = {},
}) => {
  return (
    <Flex sx={{ flexDirection: "column", ...sx, width: "100%" }}>
      {image && (
        <CardLinkWrapper to={to} noTabIndex>
          <RCAImage
            sx={{ mb: ["12px", "12px"] }}
            image={image}
            alt={alt}
            {...imageProps}
          />
        </CardLinkWrapper>
      )}

      {(tags.length || tagSlot) && (
        <Flex sx={{ alignItems: "flex-start", mb: ["6px", "0px"] }}>
          <Flex sx={{ flexWrap: "wrap", flexGrow: 1 }}>
            {tags.map(({ text, ...rest }) => (
              <Tag key={text} sx={{ mt: [0, 0], mr: 2, mb: [2, 2] }} {...rest}>
                {text}
              </Tag>
            ))}
          </Flex>
          {tagSlot && (
            <Flex sx={{ flexShrink: 0 }} ml={2}>
              {tagSlot}
            </Flex>
          )}
        </Flex>
      )}
      <Heading
        sx={{
          variant: "type.cardHeadline",
          pr: 3,
          "@media (min-width: 40em)": { pr: "80px" },
          "@media (min-width: 120em)": { pr: "200px" },
          // wordWrap: "break-word",
        }}
        as="p"
      >
        <CardLinkWrapper to={to}>
          <Markdown
            unwrapDisallowed
            disallowedTypes={["paragraph"]}
            source={title}
          />
        </CardLinkWrapper>
      </Heading>

      {children && (
        <Flex sx={{ mt: [0, 1], flexGrow: 1, ...contentSx }}>{children}</Flex>
      )}
      {footerSlot && <Flex sx={{ ...footerSx }}>{footerSlot}</Flex>}
    </Flex>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,

  image: PropTypes.object,
  // alt text for the image
  alt: PropTypes.string,

  // Possibility to link card elements
  to: PropTypes.string,

  // Array of shape { text: string, ...props}
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  ),

  // A render prop to add next to the tags
  tagSlot: PropTypes.node,

  // sx props to spread onto the content
  contentSx: PropTypes.object,

  // A render prop to add at the foot
  footerSlot: PropTypes.node,

  // sx props to spread onto the footer
  footerSx: PropTypes.object,
}

export { Card }
