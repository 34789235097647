import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { Card } from "../common/Card"
import { getSchoolProgrammesFocus } from "../../utils/data"

const CollectionItem = ({
  collection: collectionNode,
  aspectRatio,
  collectionsGrid,
}) => {
  const collection = collectionNode.node || collectionNode
  const collectionImage = collection.featuredImage

  const tags = getSchoolProgrammesFocus(
    collection.schoolsFocus,
    collection.programmesFocus,
  )

  return (
    // Hacked sizes so we can used grid gap on container
    <Box sx={{ mb: collectionsGrid ? ["12px", "36px"] : 0 }}>
      <Flex backgroundColor="background" sx={{ height: "100%" }}>
        <Card
          sx={{ w: "100%" }}
          image={collectionImage?.image?.asset?.fluid}
          imageProps={{ fluidOverrides: { aspectRatio } }}
          title={collection.collectionName}
          alt={collection.collectionName}
          tags={tags}
          to={`/collections/${collection.slug.current}`}
        >
          <Box
            sx={{
              mt: "4px",
              pr: 3,
              "@media (min-width: 40em)": { pr: "80px" },
              "@media (min-width: 120em)": { pr: "200px" },
            }}
          >
            {collection.description}
          </Box>
        </Card>
      </Flex>
    </Box>
  )
}

export default CollectionItem
