import React, { useRef, useEffect, useState } from "react" // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom"

/** @jsx jsx */
import { jsx } from "theme-ui"
import { AnimatePresence, motion } from "framer-motion"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

const LIGHTBOX_EL_ID = "rca-lightbox"

const LightboxWrapper = ({ open, children }) => {
  const domNodeRef = useRef()
  const [hasEl, setHasEl] = useState(false)

  useEffect(() => {
    domNodeRef.current = document.getElementById(LIGHTBOX_EL_ID)

    if (!domNodeRef.current) {
      const el = document.createElement("div")
      el.id = LIGHTBOX_EL_ID
      document.body.appendChild(el)
      domNodeRef.current = el
    }

    setHasEl(true)
  }, [])

  useEffect(() => {
    if (open) {
      disableBodyScroll(domNodeRef.current, { reserveScrollBarGap: true })
    } else {
      enableBodyScroll(domNodeRef.current)
    }
  }, [open])

  if (!hasEl) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "background",
            zIndex: 9999,
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    domNodeRef.current,
  )
}

export { LightboxWrapper }
