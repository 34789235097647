import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import { RCAImage } from "../common/RCAImage"

const StudentStatement = ({ student, studentStatement }) => {
  return (
    <Box>
      <Grid columns={[1, 2]} gap={0}>
        {/* Statement Image */}
        <Box sx={{ variant: "layout.rightBorder" }}>
          <Box sx={{ variant: "layout.paddedItem" }}>
            <RCAImage
              image={studentStatement?.imageFile?.childCloudinaryAsset?.fluid}
              alt={student.user.displayName}
            />
          </Box>
        </Box>
        {/* Statement */}
        <Box sx={{ variant: "layout.paddedItemTLR", mb: [7, 9] }}>
          <Box>
            {studentStatement.text && (
              <Box
                sx={{
                  pr: 3,
                  "@media (min-width: 40em)": { pr: "80px" },
                  "@media (min-width: 120em)": { pr: "200px" },
                  "p:lastOfType": { mb: 0 },
                }}
              >
                <Box
                  sx={{
                    variant: "type.description",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: studentStatement.text,
                  }}
                ></Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default StudentStatement
