import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
import SEO from "../components/navigation/seo"
/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import BlockContent from "@sanity/block-content-to-react"
import EventsBlock from "../components/pagelayout/EventsBlock"
// import StoriesBlock from "../components/pagelayout/StoriesBlock"
import StoryItem from "../components/pagelayout/StoryItem"
import { RCAImage } from "../components/common/RCAImage"
import { ListItem } from "../components/common/ListItem"
import { ExpandingSection } from "../components/common/ExpandingSection"
import CollectionsBlock from "../components/pagelayout/CollectionsBlock"

export const query = graphql`
  query SchoolTemplateQuery($slug: String!) {
    school: sanitySchool(slug: { current: { eq: $slug } }) {
      schoolName
      schoolCover {
        altText
        imageDescription
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawDescription(resolveReferences: { maxDepth: 10 })
      programmesDescription
      programmes {
        programmeName
        slug {
          current
        }
        headProgramme
        researchProgramme
        programmePathways {
          headProgramme
          programmeName
          researchProgramme
          slug {
            current
          }
        }
        programmePlatforms {
          programmeName
          slug {
            current
          }
        }
      }
      relatedEvents {
        eventName
        eventDate
        eventType
        strapline
        location
        slug {
          current
        }
      }
      relatedStories {
        featuredImage {
          image {
            asset {
              fluid(maxHeight: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
        themes {
          slug {
            current
          }
          themeName
        }
        title
        strapline
        description
        slug {
          current
        }
      }
    }
    collections: allSanityCollection(
      filter: {
        schoolsFocus: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
    ) {
      edges {
        node {
          featuredImage {
            altText
            imageDescription
            image {
              asset {
                fluid(maxWidth: 960) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          programmesFocus {
            programmeName
            slug {
              current
            }
          }
          schoolsFocus {
            schoolName
            slug {
              current
            }
          }
          description
          slug {
            current
          }
          collectionName
        }
      }
    }
  }
`

const SchoolTemplate = ({ data }) => {
  const school = data.school
  const collections = data.collections

  let schoolCollections = []

  collections.edges.forEach((collection, index) => {
    schoolCollections[index] = collection.node
  })
  const pageDescription = school.programmesDescription

  const seoImage = school.schoolCover?.image?.asset?.fluid?.src

  const checkProgrammeDescriptionExists = description => {
    if (description) {
      if (description.trim() !== "") {
        return true
      } else return false
    } else return false
  }

  return (
    <>
      <SEO
        title={school.schoolName}
        description={pageDescription}
        image={seoImage}
      />
      <div>
        <Box>
          <ListItem
            leftBorder={false}
            rightBorder={false}
            topBorder={false}
            size="large"
            tagName="h1"
            // sx={{ py: [3, 4] }}
          >
            {school.schoolName}
          </ListItem>
        </Box>
        <Grid columns={[1, 2]} gap={0}>
          <Box
            sx={{
              variant: "layout.rightBorder",
            }}
          >
            <Box sx={{ variant: "layout.paddedItem" }}>
              <RCAImage
                image={school.schoolCover?.image?.asset?.fluid}
                alt={school.schoolCover.altText}
                fluidOverrides={{ aspectRatio: 4 / 3 }}
              />
            </Box>
          </Box>
          <Box sx={{ variant: "layout.paddedItemTLR", py: [0, 4] }}>
            <Box
              sx={{
                variant: "layout.blockTextAreaContainer",
                mb: [7, 9],
              }}
            >
              <BlockContent
                blocks={school._rawDescription}
                sx={{
                  variant: "layout.blockTextArea",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Box>
          <ListItem
            leftBorder={false}
            rightBorder={false}
            size="large"
            tagName="h2"
            sx={{ py: [3, 3] }}
          >
            Programmes
          </ListItem>
        </Box>
        <Grid columns={[1, 2]} gap={0}>
          <Box sx={{ order: [2, 1], variant: "layout.rightBorder" }}>
            {checkProgrammeDescriptionExists(school.programmesDescription) && (
              <Box
                sx={{
                  variant: "layout.paddedItemTLR",
                  mb: [7, 9],
                }}
              >
                <Box
                  sx={{
                    variant: "layout.blockTextAreaContainer",
                  }}
                >
                  {school.programmesDescription}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              mb: [7, 9],
              order: [1, 2],
              mt: [6, 0],
              borderTopColor: "text",
              borderTopStyle: "solid",
              borderTopWidth: [1, 0],
            }}
          >
            <ul
              className="school-page-programmes"
              sx={{
                p: 0,
                m: 0,
                listStyle: "none",
              }}
            >
              {school.programmes.map((programme, i, arr) => {
                if (!programme.headProgramme) {
                  return null
                }

                return (
                  <ListItem
                    key={`programme-item${i}`}
                    icon="chevron"
                    to={`/programmes/${programme.slug.current}`}
                    topBorder={false}
                    leftBorder={false}
                    rightBorder={false}
                    bottomBorder={true}
                    size="navigation"
                    tagName="li"
                    sx={{ py: [1, 2] }}
                  >
                    {programme.programmeName}
                  </ListItem>
                )
              })}
            </ul>
          </Box>
        </Grid>
        {school.relatedStories?.length > 0 && (
          <Box>
            <ExpandingSection title="Stories">
              <Box
                sx={{
                  mt: [0, 4],
                  py: [3, 3],
                  mb: [7],
                  variant: "layout.paddedItemTLR",
                }}
              >
                <StoryItem
                  story={school.relatedStories[0]}
                  aspectRatio={16 / 9}
                  numColumns={1}
                />
              </Box>
            </ExpandingSection>
          </Box>
        )}
        {school.relatedEvents?.length > 0 && (
          <Box>
            <ExpandingSection title="Events">
              <EventsBlock events={school.relatedEvents} />
            </ExpandingSection>
          </Box>
        )}
        {schoolCollections?.length > 0 && (
          <ExpandingSection title="Collections" top>
            <Box sx={{ mb: ["32px", "50px"] }}>
              <CollectionsBlock
                collections={schoolCollections}
                numColumns={3}
              />
            </Box>
          </ExpandingSection>
        )}
        {/* <Box>
          <ExpandingSection title="Tags"></ExpandingSection>
        </Box> */}
      </div>
    </>
  )
}

export default SchoolTemplate
