import React from "react" // eslint-disable-line no-unused-vars

/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const SchoolsFocus = ({ schoolsFocus }) => {
  let schoolsNames = ""

  let i = ""
  for (i = 0; i < schoolsFocus.length; i++) {
    if (i <= schoolsFocus.length - 2) {
      schoolsNames += schoolsFocus[i].schoolName + ", "
    } else schoolsNames += schoolsFocus[i].schoolName
  }
  return (
    <>
      {schoolsFocus && (
        <Box sx={{ display: "inline" }}>
          <span>{schoolsNames}</span>
        </Box>
      )}
    </>
  )
}

export default SchoolsFocus
