import React, { useMemo } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { keyframes } from "@emotion/core"
/** @jsx jsx */
import { Box, Button, useThemeUI, jsx } from "theme-ui"
import { Icon, iconKeys } from "./Icon"
import { Link } from "gatsby"

// Styles for specific sizes
const tagSizes = {
  small: theme => ({
    variant: "type.tagSmall",
    pt: "6px",
    pb: "6px",
    px: "12px",
  }),
  normal: theme => ({
    variant: "type.tagNormal",
    pt: "5px",
    pb: "6px",
    px: "10px",
  }),
}

// Map for tag type additional styles
const tagTypes = {
  normal: () => ({}),
  link: () => ({
    "&:hover": {
      color: "background",
      backgroundColor: "text",
      textDecoration: "none",
    },
  }),
  button: () => ({
    "&:hover": {
      color: "background",
      backgroundColor: "text",
    },
  }),
}

const blinkKeyframes = keyframes({
  "0%, 69%": {
    opacity: 1,
  },
  "70%, 100%": {
    opacity: 0,
  },
})

// Remaps tag button to corresponding icon size
const iconSizes = {
  small: "xsmall",
  normal: "xsmall",
}

const Tag = ({
  children,
  icon,
  reverse,
  size = "small",
  onClick,
  to,
  label,
  invert,
  sx,
  squared,
  blink,
  ...props
}) => {
  const { theme } = useThemeUI()

  const tagType = useMemo(() => {
    if (to) {
      return "link"
    }

    if (onClick) {
      return "button"
    }

    return "normal"
  }, [onClick, to])

  const innerContent = useMemo(() => {
    return (
      <>
        {icon && (
          <Icon
            sx={{ [reverse ? "ml" : "mr"]: "8px" }}
            size={iconSizes[size]}
            name={icon}
          />
        )}
        {children}
      </>
    )
  }, [icon, children, size, reverse])

  const internalSx = {
    display: "inline-flex",
    flexDirection: reverse ? "row-reverse" : "row",
    alignItems: "center",
    borderWidth: "1px",
    boxShadow: "none",
    "@media (min-width: 40em)": {
      borderWidth: "1px",
      boxShadow: "0 0 0px 0.6px currentColor",
    },
    borderColor: "text",
    borderStyle: "solid",
    borderRadius: squared ? theme.sizes.tagRadiiSquared : theme.sizes.tagRadii,
    color: invert ? "background" : "text",
    backgroundColor: invert ? "text" : "background",
    textDecoration: "none",
    ...(tagSizes[size](theme) || {}),
    ...(tagTypes[tagType](theme) || {}),
    "&:first-of-type": {
      ml: 0,
    },
    ...(blink
      ? {
          animationIterationCount: "infinite",
          animationName: blinkKeyframes.toString(),
          animationDuration: `1500ms`,
        }
      : {}),
    ...sx,
  }

  if (tagType === "button") {
    return (
      <Button onClick={onClick} sx={internalSx} {...props}>
        {innerContent}
      </Button>
    )
  }

  if (tagType === "link") {
    if (to.trim().startsWith("http")) {
      return (
        <a
          href={to.trim()}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={label}
          sx={internalSx}
          {...props}
        >
          {innerContent}
        </a>
      )
    }

    return (
      <Link to={to.trim()} aria-label={label} sx={internalSx} {...props}>
        {innerContent}
      </Link>
    )
  }

  return (
    <Box sx={internalSx} {...props}>
      {innerContent}
    </Box>
  )
}

Tag.propTypes = {
  // Text to display in the tag
  children: PropTypes.string.isRequired,

  // Icon to display on the tag
  icon: PropTypes.oneOf(iconKeys),

  // Whether the icon should be reversed or not
  reverse: PropTypes.bool,

  // The size of the tag
  size: PropTypes.oneOf(["small", "normal"]),

  // Invert the tags color
  invert: PropTypes.bool,

  // Whether to square off the edges
  squared: PropTypes.bool,

  // Shouldnt be here really but oh well, a blink animation
  blink: PropTypes.bool,
}

export { Tag }
