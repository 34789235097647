import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const ExternalLinks = ({ externalLinks }) => {
  return (
    <>
      {externalLinks &&
        externalLinks.map((externalLink, index) => {
          return (
            <Box key={`external-link-${index}`}>
              {externalLink.url && (
                <Box>
                  <a
                    href={`${externalLink.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {externalLink.linkName ? (
                      <span>{externalLink.linkName}</span>
                    ) : (
                      <span>{externalLink.url}</span>
                    )}
                  </a>
                  <Box>{externalLink.description}</Box>
                </Box>
              )}
            </Box>
          )
        })}
    </>
  )
}

export default ExternalLinks
