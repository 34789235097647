import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import EventsBlock from "../EventsBlock"
import EventFeature from "../EventFeature"
import { ListItem } from "../../common/ListItem"
import { checkEitherHasItems } from "../../../utils/data"

const HomepageEvents = ({ events }) => {
  return (
    // Events Title
    <Box>
      {checkEitherHasItems(events.featuredEvent, events.events) && (
        <ListItem
          icon="chevron"
          to="/events"
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h2"
          // sx={{ py: [3, 4] }}
        >
          Events
        </ListItem>
      )}
      {/* Featured Block */}
      {events.featuredEvent && (
        <EventFeature featuredEvent={events.featuredEvent} />
      )}

      {/* Events Block */}
      {events.events && events.events.length > 0 && (
        <EventsBlock events={events.events} />
      )}
    </Box>
  )
}

export default HomepageEvents
