import React from "react"
import PropTypes from "prop-types"

import { Box } from "theme-ui"

const List = React.forwardRef(({ children, sx = {}, ...props }, ref) => {
  return (
    <Box ref={ref} as="ul" sx={{ variant: "styles.list", ...sx }} {...props}>
      {children}
    </Box>
  )
})

List.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
}

export { List }
