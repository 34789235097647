import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { toSanityLineBreaks } from "../../utils/data"

/** @jsx jsx */
import { jsx, Grid, Box, Text } from "theme-ui"
import { RCALogo } from "../common/RCALogo"

function PageFooter() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      sanitySingletonHomepage {
        externalLinks {
          linkName
          url
        }
        internalLinks {
          linkName
          url
        }
        rcaInfo
        rcaTMInfo
      }
    }
  `)
  const footer = data.sanitySingletonHomepage

  return (
    <Grid
      columns={[1, 2, 2]}
      gap={0}
      sx={{
        variant: "layout.paddedItem",
        mb: ["44px", 7],
        borderTopColor: "text",
        borderTopStyle: "solid",
        borderTopWidth: [1, 2],
        div: {
          fontSize: [1, 1],
        },
        ul: {
          listStyleType: "none",
          pl: 0,
        },
      }}
    >
      {/* Logo  */}
      <Box sx={{ order: [4, -1] }}>
        <Box sx={{ mb: [0, 3, 0], width: ["186px", "230px"] }}>
          <RCALogo />
        </Box>
      </Box>

      <Box sx={{ pl: [0, 4] }}>
        <Grid
          sx={{
            columnGap: 3,
            rowGap: [3, 5],
            gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 2fr"],
          }}
        >
          <Box sx={{ mb: [4, 3, 0], mt: [3, 0] }}>
            <Text sx={{ variant: "type.footerLinksBold" }}>Social</Text>
            <ul sx={{ my: 0, lineHeight: 1.3 }}>
              {footer.externalLinks.map((link, i) => {
                return (
                  <li key={`social-link${i}`} sx={{ my: [1, 1] }}>
                    <a
                      href={`${link.url}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      sx={{ variant: "type.footerLinks" }}
                    >
                      {link.linkName}
                    </a>
                  </li>
                )
              })}
            </ul>
          </Box>
          <Box sx={{ mb: [4, 3, 0] }}>
            <Text sx={{ variant: "type.footerLinksBold" }}>About</Text>
            <ul sx={{ my: 0, lineHeight: 1.3 }}>
              {footer.internalLinks.map((link, i) => {
                return (
                  <li key={`about-link${i}`} sx={{ my: [1, 1] }}>
                    {i < footer.internalLinks.length - 1 ? (
                      <Link to={link.url} sx={{ variant: "type.footerLinks" }}>
                        {link.linkName}
                      </Link>
                    ) : (
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        sx={{ variant: "type.footerLinks" }}
                      >
                        {link.linkName}
                      </a>
                    )}
                  </li>
                )
              })}
            </ul>
          </Box>
          <Box
            sx={{
              mb: [6, 0],
              lineHeight: 1.3,
              gridColumn: [1, "1 / span 2", "1 / span 2", "3 / span 1"],
            }}
          >
            <Text sx={{ variant: "type.footerLinksBold" }}>
              Royal College of Art
            </Text>
            <Box sx={{ pb: [4], variant: "type.footerLinks" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: toSanityLineBreaks(footer.rcaInfo),
                }}
              />
            </Box>
            <Box sx={{ variant: "type.footerLinks" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: toSanityLineBreaks(footer.rcaTMInfo),
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}

export default PageFooter
