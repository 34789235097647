import { graphql } from "gatsby"

// speed up the grid by removing base64
export const GridImage = graphql`
  fragment GridImage on File {
    childCloudinaryAsset {
      fluid(maxWidth: 300) {
        #...CloudinaryAssetFluid
        aspectRatio
        sizes
        src
        srcSet
      }
    }
  }
`

// With selectors we can return globally what key we want
// for the image. Controlled from one spot
export const gridImageSelector = node => {
  if (!node) {
    return null
  }

  return node?.imageFile?.childCloudinaryAsset?.fluid
}

// base64 for student profile page
export const FeaturedImage = graphql`
  fragment FeaturedImage on File {
    childCloudinaryAsset {
      fluid(maxWidth: 1920) {
        ...CloudinaryAssetFluid
      }
    }
  }
`

export const featuredImageSelector = node => {
  return node?.imageFile?.childCloudinaryAsset?.fluid
}

export const directFilenameImage = filename =>
  `https://studentupload.platform-3.co.uk/media/${filename}`
