import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import StudentItem from "./StudentItem"

const StudentsBlock = ({ students, numColumns }) => {
  return (
    <Grid
      columns={[1, numColumns]}
      gap={[3, 4]}
      sx={{ variant: "layout.paddedItem", mb: ["24px", "24px"] }}
    >
      {students.map((student, index) => {
        return (
          <StudentItem
            key={`/story-item${index}`}
            student={student}
            aspectRatio={4 / 3}
          />
        )
      })}
    </Grid>
  )
}

export default StudentsBlock
