import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import StoriesBlock from "../StoriesBlock"
import StoryItem from "../StoryItem"
import { ListItem } from "../../common/ListItem"

const HomepageStories = props => {
  const stories = props.stories

  const checkStories = () => {
    if (stories.featuredStory || stories.stories) {
      if (stories.stories.length > 0 || stories.featuredStory) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <Box>
      {checkStories() && (
        <ListItem
          icon="chevron"
          to="/stories"
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h2"
          // sx={{ py: [3, 4] }}
        >
          Stories
        </ListItem>
      )}

      {/* Featured Block */}
      {stories.featuredStory && (
        <Box sx={{ pb: [5, 7] }}>
          <Box sx={{ variant: "layout.paddedItemTLR" }}>
            <StoryItem
              story={stories.featuredStory}
              aspectRatio={16 / 9}
              numColumns={1}
            />
          </Box>
        </Box>
      )}

      {/* Stories Block */}
      {stories.stories && stories.stories.length > 0 && (
        <Box sx={{ pb: [6, 8] }}>
          <StoriesBlock stories={stories.stories} numColumns={2} />
        </Box>
      )}
    </Box>
  )
}

export default HomepageStories
