import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box } from "theme-ui"

const loadImage = src => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = () => {
      reject()
    }
    img.src = src
  })
}

const LazyImage = ({
  src,
  alt = "",
  sx = {},
  defaultAspectRatio = 1,
  ...props
}) => {
  const [loaded, setLoaded] = useState()

  useEffect(() => {
    let setter = true

    loadImage(src).then(() => {
      if (setter) {
        window.dispatchEvent(
          new Event("tile-loaded", {
            bubbles: true,
          }),
        )
        setLoaded(src)
      }
    })

    return () => (setter = false)
  }, [src])
  return (
    <Box
      className="lazy-image"
      sx={{
        position: "relative",
        display: "inline-block",
        backgroundColor: "muted",
        ...(loaded
          ? {}
          : {
              width: "100%",
              height: 0,
              paddingBottom: `${defaultAspectRatio * 100}%`,
            }),
        img: {
          ...(loaded
            ? {}
            : {
                position: "absolute",
                top: 0,
                left: 0,
              }),
          width: "100%",
          opacity: loaded ? 1 : 0,
          transition: "opacity 250ms",
        },
        ...sx,
      }}
      as="span"
    >
      <img src={loaded} loading="lazy" alt={alt} {...props} />
    </Box>
  )
}

LazyImage.propTypes = {
  src: PropTypes.string,
}

export { LazyImage }
