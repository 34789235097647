import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import SEO from "../components/navigation/seo"
import CollectionsBlock from "../components/pagelayout/CollectionsBlock"
import { ListItem } from "../components/common/ListItem"

export const query = graphql`
  query CollectionsPageQuery {
    collections: allSanityCollection(
      filter: { isFeatured: { eq: false } }
      sort: { order: ASC, fields: collectionName }
    ) {
      edges {
        node {
          collectionName
          featuredImage {
            altText
            imageDescription
            image {
              asset {
                fluid(maxHeight: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          programmesFocus {
            programmeName
            slug {
              current
            }
          }
          schoolsFocus {
            schoolName
            slug {
              current
            }
          }
          description
          slug {
            current
          }
        }
      }
    }
    collectionsHome: sanitySingletonCollections {
      description
      featuredCollection {
        collectionName
        featuredImage {
          altText
          imageDescription
          image {
            asset {
              fluid(maxHeight: 1920) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        programmesFocus {
          programmeName
          slug {
            current
          }
        }
        schoolsFocus {
          schoolName
          slug {
            current
          }
        }
        description
        slug {
          current
        }
      }
      title
    }
  }
`

const collectionsPage = props => {
  const collections = props.data.collections.edges
  const collectionsHome = props.data.collectionsHome

  const pageDescription = props.data.description

  return (
    <>
      <SEO title="Collections" description={pageDescription} />

      {/* Collections Page Title */}
      <Box>
        <ListItem
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h1"
          // sx={{ py: [3, 4] }}
        >
          Collections
        </ListItem>
      </Box>

      {/* Description */}
      {collectionsHome.description && (
        <Box
          sx={{
            variant: "layout.bottomBorder",
          }}
        >
          <Grid columns={[1, 2]} gap={0} sx={{ mt: [4, 7], mb: [7, 9] }}>
            <Box sx={{ px: [3, 4] }}>
              <Box sx={{ variant: "type.indexDescription" }}>
                <Box
                  sx={{
                    pr: 3,
                    "@media (min-width: 40em)": { pr: "80px" },
                    "@media (min-width: 120em)": { pr: "200px" },
                  }}
                >
                  {collectionsHome.description}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}

      {/* Featured Collection */}
      {/* {collectionsHome.featuredCollection && (
        <Box sx={{ variant: "layout.paddedItem" }}>
          <CollectionItem
            collection={collectionsHome.featuredCollection}
            aspectRatio={16 / 9}
          />
        </Box>
      )} */}
      {/* Collections Block */}
      {collections && collections.length > 0 && (
        <Box sx={{ mb: [6, 7] }}>
          <CollectionsBlock
            collections={collections}
            numColumns={3}
            orientation="portrait"
            sx={{ mb: [6, 7] }}
          />
        </Box>
      )}
    </>
  )
}

export default collectionsPage
