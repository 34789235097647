import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import BlockContent from "@sanity/block-content-to-react"
import StoryImageBlock from "./StoryImageBlock"
import { RCAEmbed } from "../../common/RCAEmbed/RCAEmbed"
// import MediaTextBlock from "./MediaTextBlock"
// import TextAreaBlock from "./TextAreaItem"

const StoryPageBlocks = ({ pageBlocks }) => {
  return (
    <Box>
      <Box>
        <Grid
          columns={[
            [1, "1fr"],
            [2, "3fr 1fr"],
          ]}
          gap={0}
          sx={{
            ".quote-0": {
              borderTopColor: "text",
              borderTopStyle: "solid",
              borderTopWidth: [1, 0],
            },
          }}
        >
          {pageBlocks?.length > 0 &&
            pageBlocks.map((pageBlock, index) => {
              return (
                <Box
                  key={`pageBlock-body-${index}`}
                  sx={{
                    variant: "layout.rightBorder",
                    gridColumnStart: "1",
                    gridRowStart: `${index + 1}`,
                  }}
                >
                  <Box sx={{ variant: "layout.paddedX", pb: [3] }}>
                    {/* Rich Text Block */}
                    {pageBlock._rawTextArea?.length > 0 && (
                      <Box
                        sx={{
                          "p:first-of-type": { mt: 0 },
                          pt: [3, 5],
                          pr: [0, 4],
                          fontSize: [2, 3],
                          blockquote: {
                            fontSize: [4, 7],
                            py: 0,
                            mx: 0,
                            mt: [0, 0],
                            mb: [4, 4],
                          },
                        }}
                      >
                        <Box
                          sx={{
                            pr: [0, 3],
                            "@media (min-width: 40em)": { pr: "80px" },
                            "@media (min-width: 120em)": { pr: "200px" },
                          }}
                        >
                          <BlockContent blocks={pageBlock._rawTextArea} />
                        </Box>
                      </Box>
                    )}
                    {/* Image Group */}
                    {pageBlock.imageGroup?.imageItemBlock.length > 0 && (
                      <Box
                        sx={{
                          py: [0, 3],
                          pb:
                            pageBlock.imageGroup.imageItemBlock.length === 1
                              ? [0, 4]
                              : "0px",
                        }}
                      >
                        <StoryImageBlock
                          imageBlock={pageBlock.imageGroup.imageItemBlock}
                        />
                      </Box>
                    )}
                    {/* Hosted Media */}
                    {pageBlock.hostedMedia && (
                      <Box>
                        <RCAEmbed mediaUrl={pageBlock.hostedMedia.mediaUrl} />
                        <Box sx={{ pt: [2, 3], mb: [3, 0] }}>
                          {pageBlock.hostedMedia.title}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )
            })}
          {pageBlocks?.length > 0 &&
            pageBlocks.map((pageBlock, indexQuote) => {
              return (
                <Box
                  className={`quote-${indexQuote}`}
                  key={`pageBlock-quote-${indexQuote}`}
                >
                  {pageBlock._rawTextFootnote ? (
                    <Box
                      sx={{
                        px: [3, 3],
                        pt: [3, 4],
                        pb: [0, 4],
                        gridColumnStart: ["1", "2"],
                        gridRowStart: ["auto", `${indexQuote + 1}`],
                      }}
                    >
                      <Box
                        sx={{
                          pr: 3,
                          "@media (min-width: 40em)": { pr: "12px" },
                          "@media (min-width: 120em)": { pr: "60px" },
                        }}
                      >
                        <BlockContent blocks={pageBlock._rawTextFootnote} />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: [0, "auto"],
                        px: 0,
                        py: 0,
                        gridColumnStart: ["1", "2"],
                        gridRowStart: ["auto", `${indexQuote + 1}`],
                      }}
                    >
                      &nbsp;
                    </Box>
                  )}
                </Box>
              )
            })}
        </Grid>
        <Grid
          columns={[
            [1, "1fr"],
            [2, "3fr 1fr"],
          ]}
          gap={0}
        >
          <Box sx={{ variant: "layout.rightBorder" }}>
            <Box sx={{ py: 2, height: ["36px", "60px"] }}>&nbsp;</Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default StoryPageBlocks
