// fonts object
export default {
  fonts: {
    body:
      '"Untitled Regular", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Untitled Regular", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    monospace: "Menlo, monospace",
  },
  // original fontSizes: [12, 14, 16, 20, 25, 36, 48, 56, 64, 96],

  //numbers: [ 0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12]
  fontSizes: [11, 14, 16, 20, 26, 28, 32, 36, 48, 56, 64, 72, 96],
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 700,
  },
  lineHeights: {
    body: 1.25,
    heading: 1.125,
    headingStories: 1.056,
    cardHeadline: 1.143,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    primary: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
}
