let ticking = false
let hasRegisteredListener = false
const callbacks = []

function requestTick() {
  requestAnimationFrame(() => {
    callbacks.forEach(cb => cb())
    ticking = false
  })
  ticking = true
}

function onScroll() {
  if (!ticking) {
    requestTick()
  }
}

export function removeScrollListener(cb) {
  const idx = callbacks.indexOf(cb)

  if (idx >= 0) {
    callbacks.splice(idx, 1)
  }

  if (callbacks.length === 0) {
    window.removeEventListener("scroll", onScroll)
  }
}

export function addScrollListener(cb) {
  if (!hasRegisteredListener) {
    window.addEventListener("scroll", onScroll)
  }

  callbacks.push(cb)

  return () => removeScrollListener(cb)
}
