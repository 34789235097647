import React from "react"

import { Button } from "theme-ui"
import { Icon } from "./Icon"

const IconButton = ({ icon, iconProps = {}, sx = {}, onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      sx={{
        border: 0,
        padding: 0,
        backgroundColor: "transparent",
        "&:hover": {
          color: "text",
          backgroundColor: "transparent",
        },
        ...sx,
      }}
      {...props}
    >
      <Icon name={icon} {...iconProps} />
    </Button>
  )
}

export { IconButton }
