import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import { graphql } from "gatsby"
import SEO from "../components/navigation/seo"
import BlockContent from "@sanity/block-content-to-react"
import Sponsors from "../components/commonblocks/Sponsors"
import SchoolsFocus from "../components/commonblocks/SchoolsFocus"
import ProgrammesFocus from "../components/commonblocks/ProgrammesFocus"
import PresenterGuests from "../components/pagelayout/event/PresenterGuests"
import Themes from "../components/commonblocks/Themes"
import { RCAImage } from "../components/common/RCAImage"
import DateTimeBlocks from "../components/commonblocks/DateTimeBlocks"
import { ListItem } from "../components/common/ListItem"
import EventBookingDetails from "../components/pagelayout/event/EventBookingDetails"
import { checkEitherHasItems } from "../utils/data"
import MediaTextBlock from "../components/pagelayout/MediaTextBlock"
import { ExpandingSection } from "../components/common/ExpandingSection"
import { Tag } from "../components/common/Tag"
import StudentsBlock from "../components/pagelayout/StudentsBlock"

export const query = graphql`
  query EventTemplateQuery($slug: String!) {
    event: sanityEvent(slug: { current: { eq: $slug } }) {
      featuredImage {
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
        altText
        imageDescription
      }
      eventDate
      eventType
      location
      eventName
      strapline
      description
      _rawBody(resolveReferences: { maxDepth: 10 })
      themes {
        themeName
        slug {
          current
        }
      }
      sponsors {
        sponsorName
        sponsorUrl
        sponsorImage {
          altText
          imageDescription
          image {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      schoolsFocus {
        schoolName
        slug {
          current
        }
      }
      programmesFocus {
        programmeName
        slug {
          current
        }
      }
      duration
      eventBookingDetails {
        linkName
        url
        description
      }
      eventOrganiser
      presenterGuests {
        role
        name
        shortBio
      }
      artistGraduates {
        graduateName
        url {
          description
          linkName
          url
        }
        studentImage {
          image {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
          altText
        }
        graduateProgramme {
          programmeName
          slug {
            current
          }
        }
      }
      listed
      videoItems {
        description
        media {
          mediaUrl
          selectPlatform
          title
        }
      }
      audioItems {
        description
        media {
          mediaUrl
          selectPlatform
          title
        }
      }
    }
  }
`

const EventTemplate = props => {
  const { event } = props.data

  const checkThemesIsNotEmpty = event => {
    if (event.themes) {
      if (event.themes.length > 0) {
        if (event.themes[0].trim() !== "") {
          return true
        } else return false
      } else return false
    } else return false
  }
  const seoImage = event.featuredImage?.image?.asset?.fluid?.src
  const seoDescription = event.description ? event.description : event.eventName

  return (
    <>
      <SEO
        title={event.eventName}
        description={seoDescription}
        image={seoImage}
      />
      <Box>
        {/* Back to Events */}
        <Box>
          <ListItem
            to={`/events`}
            icon="arrow-left"
            leftBorder={false}
            rightBorder={false}
            bottomBorder={true}
            topBorder={false}
            size="navigation"
            reverse
            justifyContent="flex-end"
          >
            Events
          </ListItem>
        </Box>

        {/* Featured Image */}
        <Box>
          <Box sx={{ variant: "layout.paddedItemTLR" }}>
            <RCAImage
              image={event.featuredImage?.image?.asset?.fluid}
              alt={event.featuredImage?.altText}
              fluidOverrides={{ aspectRatio: 16 / 9 }}
            />
          </Box>
        </Box>
        {/* Top Tags */}
        <Box sx={{ variant: "layout.paddedItemTLR" }}>
          {/* Date */}
          {event.eventDate && (
            <Box sx={{ display: "inline" }}>
              <DateTimeBlocks isodatetime={event.eventDate} />
            </Box>
          )}
          {/* Event Type */}
          {event.eventType && (
            <Tag sx={{ mr: 2, mb: [2, 3] }}>{event.eventType}</Tag>
          )}
          {/* Location */}
          {event.location && (
            <Tag sx={{ mr: 2, mb: [2, 3] }}>{event.location}</Tag>
          )}
        </Box>

        <Box sx={{ variant: "layout.paddedItemLR", mb: [6, 7] }}>
          {/* Event Title */}
          <Box sx={{ variant: "type.headline", mt: ["-2px", 0] }}>
            {event.eventName}
          </Box>
          {/* Strapline */}
          {event.strapline && (
            <Box sx={{ variant: "type.description", mt: [1, 1] }}>
              {event.strapline}
            </Box>
          )}
        </Box>
        <Grid columns={[1, 2]} gap={0}>
          <Box sx={{ variant: "layout.paddedItemLR" }}>
            {/* Body */}
            {event._rawBody && (
              <Box
                sx={{
                  figure: { margin: 0, img: { maxWidth: "100%", mb: [6, 7] } },
                }}
              >
                <BlockContent blocks={event._rawBody} />
              </Box>
            )}
            {/* Info Box */}
          </Box>
        </Grid>

        <Box sx={{ mb: [7, 9] }}>
          <Box sx={{ variant: "layout.paddedItemLR", mb: [6, 7] }}>
            {/* Themes */}
            {checkThemesIsNotEmpty(event.themes) && (
              <Box sx={{ display: "inline" }}>
                <span sx={{ fontWeight: "bold" }}>Themes: </span>
                <Themes themes={event.themes} />
              </Box>
            )}
            {/* Sponsors */}
            {event.sponsors?.length > 0 && (
              <Box>
                <span sx={{ fontWeight: "bold" }}>With the kind support: </span>
                <Sponsors sponsors={event.sponsors} />
              </Box>
            )}
            {/* Schools Focus */}
            {checkEitherHasItems(event.schoolsFocus, event.programmesFocus) && (
              <Box>
                <span sx={{ fontWeight: "bold" }}>School/Programme: </span>
                {event.schoolsFocus?.length > 0 && (
                  <Box sx={{ display: "inline" }}>
                    <SchoolsFocus schoolsFocus={event.schoolsFocus} />
                  </Box>
                )}
                {event.programmesFocus?.length > 0 &&
                  event.schoolsFocus?.length > 0 && (
                    <Box sx={{ display: "inline" }}>
                      <span>, </span>
                    </Box>
                  )}
                {event.programmesFocus?.length > 0 && (
                  <Box sx={{ display: "inline" }}>
                    <ProgrammesFocus programmesFocus={event.programmesFocus} />
                  </Box>
                )}
              </Box>
            )}
            {/* Event Duration */}
            {event.duration && (
              <Box>
                <span sx={{ fontWeight: "bold" }}>Estimated Duration: </span>
                {event.duration}
              </Box>
            )}
          </Box>

          {/* Event Booking Details */}
          {event.eventBookingDetails && (
            <Box
              sx={{ variant: "layout.paddedItemLR", maxWidth: ["100%", "25%"] }}
            >
              <EventBookingDetails
                eventBookingDetails={event.eventBookingDetails}
              />
            </Box>
          )}
        </Box>

        {/* Students */}
        {event.artistGraduates?.length > 0 && (
          <ExpandingSection title="Students">
            <Box>
              <StudentsBlock students={event.artistGraduates} numColumns={3} />
            </Box>
          </ExpandingSection>
        )}

        {/* Speakers */}
        {event.videoItems?.length > 0 && (
          <ExpandingSection title="Speakers">
            <Box sx={{ px: [3, 4] }}>
              <PresenterGuests presenterGuests={event.presenterGuests} />
            </Box>
          </ExpandingSection>
        )}

        {/* Video Archive */}
        {event.videoItems?.length > 0 && (
          <ExpandingSection title="Video Archive">
            <Box sx={{ variant: "layout.paddedItem", mb: [7, 9] }}>
              <MediaTextBlock mediaItems={event.videoItems} />
            </Box>
          </ExpandingSection>
        )}

        {/* Audio Archive */}
        {event.audioItems?.length > 0 && (
          <ExpandingSection title="Audio Archive">
            <Box sx={{ variant: "layout.paddedItem", mb: [7, 9] }}>
              <MediaTextBlock mediaItems={event.audioItems} />
            </Box>
          </ExpandingSection>
        )}
      </Box>
    </>
  )
}

export default EventTemplate
