import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"

import { Flex } from "theme-ui"
import { ReactComponent as SearchIcon } from "../../images/icons/search.svg"
import { ReactComponent as PlusIcon } from "../../images/icons/plus.svg"
import { ReactComponent as SmallPlusIcon } from "../../images/icons/small-plus.svg"
import { ReactComponent as MinusIcon } from "../../images/icons/minus.svg"
import { ReactComponent as ChevronIcon } from "../../images/icons/chevron.svg"
import { ReactComponent as ChevronDownIcon } from "../../images/icons/chevron-down.svg"
import { ReactComponent as ArrowLeftIcon } from "../../images/icons/arrow-left.svg"
import { ReactComponent as ArrowRightIcon } from "../../images/icons/arrow-right.svg"
import { ReactComponent as TickIcon } from "../../images/icons/tick.svg"
import { ReactComponent as XIcon } from "../../images/icons/x.svg"
import { ReactComponent as LinkIcon } from "../../images/icons/link.svg"

const iconMap = {
  search: SearchIcon,
  plus: PlusIcon,
  "small-plus": SmallPlusIcon,
  minus: MinusIcon,
  chevron: ChevronIcon,
  "chevron-down": ChevronDownIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-right": ArrowRightIcon,
  tick: TickIcon,
  x: XIcon,
  link: LinkIcon,
}

export const iconKeys = Object.keys(iconMap)

const Icon = ({ name, size = "normal", sx = {}, ...props }) => {
  if (!iconKeys.includes(name)) {
    return null
  }

  const IconName = iconMap[name]

  return (
    <Flex
      sx={{
        variant: `icon.${size}`,
        flexShrink: 0,
        ...sx,
      }}
      {...props}
    >
      <IconName width="100%" height="100%" />
    </Flex>
  )
}

Icon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  size: PropTypes.oneOf(["xsmall", "small", "normal", "medium", "large"]),
  sx: PropTypes.object,
}

export { Icon }
