import React from "react"

import PropTypes from "prop-types"
import { Box } from "theme-ui"

import { Icon } from "./Icon"

const PlusIcon = ({
  startScale = 1,
  scale = 0,
  iconProps = {},
  toggle = false,
  timing = 0.25,
  customSx = {},
}) => {
  return (
    <Box
      as="span"
      sx={{
        flexShrink: 0,
        "path.plus_svg__vertical-line": {
          transform: toggle ? `scaleY(${scale})` : `scaleY(${startScale})`,
          transformOrigin: "center",
          transition: `transform ${timing}s ease-in-out`,
        },
        ...customSx,
      }}
    >
      <Icon name="plus" {...iconProps} />
    </Box>
  )
}

PlusIcon.propTypes = {
  iconProps: PropTypes.object,
  scale: PropTypes.number,
  startScale: PropTypes.number,
  toggle: PropTypes.bool,
  timing: PropTypes.number,
  sx: PropTypes.object,
}

export { PlusIcon }
