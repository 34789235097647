import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import EventItem from "../EventItem"

const EventsPageBlock = ({ events }) => {
  return (
    <Grid columns={[1, 2]} gap={0}>
      {events.map((event, index) => {
        return (
          <EventItem
            key={`even-item-${index}`}
            index={index}
            event={event.node}
            isOdd={(index + 1) % 2}
            evenTotal={events.length % 2 === 0}
          />
        )
      })}
    </Grid>
  )
}

export default EventsPageBlock
