import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { Card } from "../common/Card"
import { Link } from "gatsby"
import { getRelativeStudentLink } from "../../utils/data"

const StudentItem = ({ student, aspectRatio }) => {
  // Student Item
  const studentImage = student.studentImage

  const studentTags = []
  // studentTags[0] = { text: student.graduateProgramme.programmeName }
  let studentUrl
  if (student.url) {
    if (student.url.url) {
      studentUrl = getRelativeStudentLink(student.url.url)
    }
  }

  return (
    <Box sx={{ mb: [3, 4] }}>
      <Flex backgroundColor="background" sx={{ height: "100%" }}>
        <Card
          image={studentImage?.image?.asset?.fluid}
          imageProps={{ fluidOverrides: { aspectRatio: aspectRatio } }}
          title={student.graduateName}
          to={studentUrl}
          tags={studentTags}
          // tagSlot={<Tag invert>Live</Tag>}
          // footerSlot={<Tag to={`${student.url.url}`}>Read More</Tag>}
        >
          {student.graduateProgramme?.programmeName}
        </Card>
      </Flex>
    </Box>
  )
}

export default StudentItem
