import React from "react" // eslint-disable-line no-unused-vars
import ReactPlayer from "react-player"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

// For Platform options see:
// https://www.npmjs.com/package/react-player

const SoundCloud = ({ urlUri }) => {
  return (
    <Box className="rca-embed-platform soundcloud">
      <ReactPlayer
        url={urlUri}
        config={{
          soundcloud: {
            options: {
              autoplay: false,
              color: "#000000", // Does not work, possibly only for Flash Player
              sharing: true,
              download: true,
              show_artwork: false,
            },
          },
        }}
        controls
        playing={false}
        width="100%"
        height="30%"
      />
    </Box>
  )
}

export default SoundCloud
