import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
import SEO from "../components/navigation/seo"
import BlockContent from "@sanity/block-content-to-react"
/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import Loadable from "@loadable/component"
import { RCAImage } from "../components/common/RCAImage"
import { RCAEmbed } from "../components/common/RCAEmbed/RCAEmbed"
import EventsBlock from "../components/pagelayout/EventsBlock"
import StoriesBlock from "../components/pagelayout/StoriesBlock"
import { ExpandingSection } from "../components/common/ExpandingSection"
import { ListItem } from "../components/common/ListItem"
import PathwayPlatformBlock from "../components/pagelayout/programme/PathwayPlatformBlock"
import ProgrammeSponsors from "../components/pagelayout/programme/ProgrammeSponsors"
import CollectionsBlock from "../components/pagelayout/CollectionsBlock"

import TagsFromStudents, {
  studentsToTags,
} from "../components/pagelayout/TagsFromStudents"
import { checkEitherHasItems } from "../utils/data"

const LazyStudentGrid = Loadable(() =>
  import("../components/student/StudentGrid"),
)

export const query = graphql`
  query ProgrammeTemplateQuery($code: String!) {
    programme: sanityProgramme(programmeCode: { eq: $code }) {
      programmeName
      slug {
        current
      }
      headProgramme
      researchProgramme
      pathwayProgramme
      platformProgramme
      programmePathways {
        programmeName
        slug {
          current
        }
      }
      programmePlatforms {
        programmeName
        slug {
          current
        }
      }
      school {
        schoolName
        slug {
          current
        }
      }
      _rawDescription
      description {
        children {
          text
        }
      }
      programmeCover {
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
        imageDescription
        altText
      }
      videoCover {
        mediaUrl
        selectPlatform
        title
      }
      relatedArtists {
        artistName
        artistUrl
      }
      relatedStories {
        featuredImage {
          altText
          image {
            asset {
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageDescription
        }
        # themes {
        #   themeName
        #   slug {
        #     current
        #   }
        # }
        title
        strapline
        description
        slug {
          current
        }
      }
      relatedEvents {
        eventName
        eventDate
        eventType
        strapline
        location
        slug {
          current
        }
      }
      externalLinks {
        linkName
        url
      }
      sponsors {
        sponsorName
        sponsorUrl
        description
        sponsorImage {
          altText
          image {
            asset {
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    students: allStudentIndex(filter: { programmeCode: { eq: $code } }) {
      edges {
        node {
          id
          slug
          name
          image {
            imageFile {
              ...GridImage
            }
          }
          tags {
            slug
            text
          }
        }
      }
    }
    collections: allSanityCollection(
      filter: {
        programmesFocus: { elemMatch: { programmeCode: { eq: $code } } }
      }
    ) {
      edges {
        node {
          featuredImage {
            altText
            imageDescription
            image {
              asset {
                fluid(maxHeight: 960) {
                  src
                }
              }
            }
          }
          programmesFocus {
            programmeName
            slug {
              current
            }
          }
          schoolsFocus {
            schoolName
            slug {
              current
            }
          }
          description
          slug {
            current
          }
          collectionName
        }
      }
    }
  }
`

const ProgrammeTemplate = ({ data }) => {
  const students = data.students
  const programmeImage = data.programme.programmeCover
  const collections = data.collections
  const programme = data.programme

  let numAboutTitleColumns
  let numAboutTextColumns
  if (
    checkEitherHasItems(
      programme.programmePathways,
      programme.programmePlatforms,
    )
  ) {
    numAboutTitleColumns = 2
    numAboutTextColumns = 1
  } else {
    numAboutTitleColumns = 1
    numAboutTextColumns = 2
  }

  let schoolCollections = []

  collections.edges.forEach((collection, index) => {
    schoolCollections[index] = collection.node
  })

  const seoImage = programme.programmeCover?.image?.asset?.fluid?.src
  const seoDescription =
    programme.description?.length > 0
      ? programme.description[0]?.children[0]?.text
      : ""

  return (
    <>
      <SEO
        title={programme.programmeName}
        description={seoDescription}
        image={seoImage}
      />
      <Box>
        {programme.school && programme.school.slug && (
          <ListItem
            to={`/schools/${programme.school.slug.current}`}
            icon="arrow-left"
            topBorder={false}
            leftBorder={false}
            rightBorder={false}
            bottomBorder={false}
            size="navigation"
            reverse
            justifyContent="flex-end"
          >
            {programme.school.schoolName}
          </ListItem>
        )}
        <Box>
          <ListItem
            leftBorder={false}
            rightBorder={false}
            size="large"
            tagName="h1"
            // sx={{ py: [3, 4] }}
          >
            {programme.programmeName}
          </ListItem>
        </Box>
        <Box sx={{ variant: "layout.paddedItem" }}>
          <Box>
            {programme.videoCover?.mediaUrl ? (
              <RCAEmbed mediaUrl={programme.videoCover.mediaUrl} />
            ) : (
              <RCAImage
                image={programmeImage?.image?.asset?.fluid}
                alt={programmeImage?.altText}
                fluidOverrides={{ aspectRatio: 16 / 9 }}
              />
            )}
          </Box>
        </Box>
        {/* About Block */}
        <Grid columns={[1, numAboutTitleColumns]} gap={0}>
          <Box
            sx={
              numAboutTitleColumns === 2 && {
                variant: "layout.rightBorder",
              }
            }
          >
            <Box>
              <Box>
                <ListItem
                  leftBorder={false}
                  rightBorder={false}
                  size="large"
                  tagName="h2"
                >
                  About
                </ListItem>
              </Box>
            </Box>
            <Box>
              <Grid columns={[1, numAboutTextColumns]} gap={0}>
                <Box sx={{ variant: "layout.paddedItemTLR", mb: [7, 9] }}>
                  <Box
                    sx={{
                      variant: "layout.blockTextAreaContainer",
                    }}
                  >
                    <BlockContent
                      blocks={programme._rawDescription}
                      sx={{
                        variant: "layout.blockTextArea",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
          {/* Pathways and Platforms Links */}
          <Box sx={{ mb: numAboutTextColumns === 1 ? [7, 9] : 0 }}>
            <PathwayPlatformBlock
              programmePathways={programme.programmePathways}
              programmePlatforms={programme.programmePlatforms}
            />
          </Box>
        </Grid>

        <Box>
          <ExpandingSection title="Students" initialExpanded>
            <LazyStudentGrid students={students} />
          </ExpandingSection>
        </Box>

        {programme.relatedEvents?.length > 0 && (
          <Box>
            <ExpandingSection title="Events">
              <EventsBlock events={programme.relatedEvents} />
            </ExpandingSection>
          </Box>
        )}
        {programme.relatedStories?.length > 0 && (
          <Box>
            <ExpandingSection title="Stories">
              <Box sx={{ pt: [3, 4], pb: [5, 7] }}>
                <StoriesBlock
                  stories={programme.relatedStories}
                  numColumns={2}
                />
              </Box>
            </ExpandingSection>
          </Box>
        )}
        {schoolCollections?.length > 0 && (
          <ExpandingSection title="Collections">
            <Box sx={{ mb: ["32px", "50px"] }}>
              <CollectionsBlock
                collections={schoolCollections}
                numColumns={3}
              />
            </Box>
          </ExpandingSection>
        )}
        <Box>
          <ExpandingSection initialExpanded title="Tags">
            <Box sx={{ mb: [6, 8] }}>
              <TagsFromStudents tags={studentsToTags(students, 5)} hasMore />
            </Box>
          </ExpandingSection>
        </Box>
      </Box>
      {programme.sponsors?.length > 0 && (
        <ExpandingSection title="Sponsors">
          <Box>
            <ProgrammeSponsors sponsors={programme.sponsors} numColumns={3} />
          </Box>
        </ExpandingSection>
      )}
    </>
  )
}

export default ProgrammeTemplate
