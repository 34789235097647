import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Themes = ({ themes }) => {
  let themeNames = ""

  let i = ""
  for (i = 0; i < themes.length; i++) {
    if (i <= themes.length - 2) {
      themeNames += themes[i].themeName + ", "
    } else themeNames += themes[i].themeName
  }

  return <Box sx={{ mb: 20, display: "inline" }}>{themeNames}</Box>
}

export default Themes
