import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import Loadable from "@loadable/component"
import SEO from "../components/navigation/seo"
// import StudentGrid from "../components/student/StudentGrid"
import { ListItem } from "../components/common/ListItem"

const LazyStudentGrid = Loadable(() =>
  import("../components/student/StudentGrid"),
)

export const query = graphql`
  query GraduatesPageGridMongoQuery {
    students: allStudentIndex {
      edges {
        node {
          id
          slug
          name
          image {
            imageFile {
              ...GridImage
            }
          }
        }
      }
    }
  }
`

const graduatesGridMongo = ({ data }) => {
  const { students } = data

  const pageDescription =
    "Discover graduate work by Royal College of Art students from around the world. Engage with a programme of events, explore collections by guest curators, and immerse yourself in the thinking of the world’s most talented emerging artists and designers."
  return (
    <Box>
      <SEO title="Students" description={pageDescription} />
      <Box>
        <ListItem
          // icon="chevron"
          // to="/events"
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h1"
          // sx={{ py: [3, 4] }}
        >
          Students
        </ListItem>
      </Box>
      <LazyStudentGrid students={students} isMain />
    </Box>
  )
}

export default graduatesGridMongo
