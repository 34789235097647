import React, { useRef, useLayoutEffect } from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Grid, Box, useThemeUI } from "theme-ui"
import { useResponsiveValue } from "@theme-ui/match-media"
import SEO from "../components/navigation/seo"
import BlockContent from "@sanity/block-content-to-react"
import { ExpandingSection } from "../components/common/ExpandingSection"

export const query = graphql`
  query AboutPageQuery {
    sanitySingletonAbout {
      _rawContactUs(resolveReferences: { maxDepth: 10 })
      _rawAccessibility(resolveReferences: { maxDepth: 10 })
      _rawTandcs(resolveReferences: { maxDepth: 10 })
      _rawPrivacyPolicy(resolveReferences: { maxDepth: 10 })
      _rawAboutThisSite(resolveReferences: { maxDepth: 10 })
      _rawAboutUs(resolveReferences: { maxDepth: 10 })
    }
  }
`

const AboutPage = props => {
  const about = props.data.sanitySingletonAbout
  const hash = props.location.hash
  const { theme } = useThemeUI()
  const scrollOffset =
    useResponsiveValue(theme.layout.header.height) -
    useResponsiveValue(theme.sizes.borderWidth)
  const timerDelay = 1000

  const accessibility = useRef(null)
  const termsConditions = useRef(null)
  const privacyPolicy = useRef(null)
  // const aboutThisSite = useRef(null)
  // about-this-site
  // terms-conditions
  // privacy-policy
  // about-this-site

  // respond to changing location.hash
  // timer is used as there's some animation on the dom
  // without a timer the position is wrong
  useLayoutEffect(() => {
    if (hash) {
      let timer
      switch (hash) {
        case "#accessibility":
          timer = setTimeout(() => {
            window.scrollTo({
              top: accessibility.current.offsetTop - scrollOffset,
              behavior: "smooth",
            })
          }, timerDelay)
          return () => {
            clearTimeout(timer)
          }
        case "#terms-conditions":
          timer = setTimeout(() => {
            window.scrollTo({
              top: termsConditions.current.offsetTop - scrollOffset,
              behavior: "smooth",
            })
          }, timerDelay)
          return () => {
            clearTimeout(timer)
          }
        case "#privacy-policy":
          timer = setTimeout(() => {
            window.scrollTo({
              top: privacyPolicy.current.offsetTop - scrollOffset,
              behavior: "smooth",
            })
          }, timerDelay)
          return () => {
            clearTimeout(timer)
          }
        default:
          break
        // case "#about-this-site":
        //   timer = setTimeout(() => {
        //     window.scrollTo({
        //       top: aboutThisSite.current.offsetTop - scrollOffset,
        //       behavior: "smooth",
        //     })
        //   }, timerDelay)
        //   return () => {
        //     clearTimeout(timer)
        //   }
        //   break
      }
    }
    // this will clear Timeout when component unmount like in willComponentUnmount
  }, [hash]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        "div p >strong": { fontWeigth: "bold !important" },
        h3: { fontWeight: "bold", my: [3, 4] },
      }}
    >
      <SEO title="About" />
      {/* About Page Title */}
      {about._rawContactUs?.length > 0 && (
        <Box>
          <ExpandingSection title="About RCA2020" top={false}>
            <Grid columns={[1, 2]}>
              <Box sx={{ variant: "layout.paddedItemTLR", mb: [7, 8] }}>
                <Box
                  sx={{
                    variant: "layout.blockTextArea",
                  }}
                >
                  <BlockContent blocks={about._rawContactUs} />
                </Box>
              </Box>
            </Grid>
          </ExpandingSection>
        </Box>
      )}

      {/* Terms & Conditions */}
      {about._rawTandcs?.length > 0 && (
        <Box ref={termsConditions}>
          <ExpandingSection title="Terms & Conditions">
            <Grid columns={[1, 2]}>
              <Box sx={{ variant: "layout.paddedItem" }}>
                <BlockContent
                  blocks={about._rawTandcs}
                  sx={{
                    variant: "layout.blockTextArea",
                  }}
                />
              </Box>
            </Grid>
          </ExpandingSection>
        </Box>
      )}

      {/* Data Protection, Privacy & Cookies */}
      {about._rawPrivacyPolicy?.length > 0 && (
        <Box ref={privacyPolicy}>
          <ExpandingSection title="Data Protection, Privacy &amp; Cookies">
            <Grid columns={[1, 2]}>
              <Box sx={{ variant: "layout.paddedItem" }}>
                <BlockContent
                  blocks={about._rawPrivacyPolicy}
                  sx={{
                    variant: "layout.blockTextArea",
                  }}
                />
              </Box>
            </Grid>
          </ExpandingSection>
        </Box>
      )}

      {/* Accessibility */}
      {about._rawAccessibility?.length > 0 && (
        <Box ref={accessibility}>
          <ExpandingSection title="Accessibility">
            <Grid columns={[1, 2]} id="accessibility">
              <Box sx={{ variant: "layout.paddedItem" }}>
                <Box sx={{}}>
                  <BlockContent
                    blocks={about._rawAccessibility}
                    sx={{
                      variant: "layout.blockTextArea",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </ExpandingSection>
        </Box>
      )}
    </Box>
  )
}

export default AboutPage
