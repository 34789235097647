import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import SEO from "../components/navigation/seo"
// import EventFeature from "../components/pagelayout/EventFeature"
import { ListItem } from "../components/common/ListItem"
import EventsPageBlock from "../components/pagelayout/events/EventsPageBlock"
import { ExpandingSection } from "../components/common/ExpandingSection"
import { checkEventIsPresentFutureOrFeatured } from "../utils/data"

export const query = graphql`
  query EventsPageQuery {
    events: allSanityEvent(
      filter: {}
      sort: { order: ASC, fields: eventDate }
    ) {
      edges {
        node {
          isFeatured
          duration
          eventDate
          eventType
          location
          eventName
          strapline
          themes {
            slug {
              current
            }
            themeName
          }
          slug {
            current
          }
        }
      }
    }
    eventsHome: sanitySingletonEvents {
      description
    }
  }
`

const eventsPage = props => {
  const events = props.data.events.edges
  // const pastevents = props.data.pastevents.edges
  const eventsHome = props.data.eventsHome

  const pageDescription = props.data.description

  let pastEvents = []
  let presentFutureEvents = []
  let i = 0
  events.forEach((event, index) => {
    if (checkEventIsPresentFutureOrFeatured(event)) {
      presentFutureEvents[i] = event
      i++
    } else {
      pastEvents.unshift(event)
    }
  })

  return (
    <Box>
      <SEO title="Events" description={pageDescription} />
      {/* Events Page Title */}
      <Box>
        <ListItem
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h1"
          // sx={{ py: [3, 4] }}
        >
          Events
        </ListItem>
      </Box>

      {/* Events Description */}
      {eventsHome.description && (
        <Box
        // sx={{
        //   variant: "layout.bottomBorder",
        // }}
        >
          <Grid columns={[1, 2]} gap={0} sx={{ mt: [4, 7], mb: [7, 9] }}>
            <Box sx={{ px: [3, 4] }}>
              <Box sx={{ variant: "type.indexDescription" }}>
                <Box
                  sx={{
                    pr: 3,
                    "@media (min-width: 40em)": { pr: "80px" },
                    "@media (min-width: 120em)": { pr: "200px" },
                  }}
                >
                  {eventsHome.description}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}

      {/* Events Block */}
      {events && events.length > 0 && (
        <div
          sx={{
            borderColor: "text",
            borderStyle: "solid",
            borderWidth: 0,
            borderTopWidth: [1, 2],
          }}
        >
          <EventsPageBlock events={presentFutureEvents} />
        </div>
      )}

      {/* Past Events Block */}
      {pastEvents?.length > 0 && (
        <Box>
          <ExpandingSection title="Past Events">
            <Box>
              <EventsPageBlock events={pastEvents} />
            </Box>
          </ExpandingSection>
        </Box>
      )}
    </Box>
  )
}

export default eventsPage
