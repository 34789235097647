import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Sketchfab = ({ urlUri }) => {
  // Check If Shortened URL

  let embeddedModel = false
  let linkedModel = false

  if (urlUri.includes("sketchfab.com/3d-models/")) embeddedModel = true
  if (urlUri.includes("skfb.ly")) linkedModel = true

  return (
    <>
      {embeddedModel && (
        <Box
          className="rca-embed-platform sketchfab sketchfab-embed-wrapper"
          sx={{
            position: "relative",
            pt: "50%" /* Player ratio: 100 / (1280 / 720) */,
          }}
        >
          <iframe
            title={urlUri}
            src={`${urlUri}/embed?preload=1&amp;ui_controls=0&amp;ui_infos=0&amp;ui_inspector=0&amp;ui_stop=0&amp;ui_watermark=1&amp;ui_watermark_link=0`}
            frameBorder="0"
            allow="autoplay; fullscreen;"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </Box>
      )}
      {linkedModel && (
        <div className="sketchfab-embed-wrapper">
          <a href={urlUri} target="_blank" rel="noreferrer">
            Link to SketchFab Model Viewer
          </a>
        </div>
      )}
    </>
  )
}

export default Sketchfab
