import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Tag } from "../common/Tag"

const DateTimeBlocks = ({ isodatetime }) => {
  const date = new Date(isodatetime)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const month = months[date.getMonth()]
  const offset = -(date.getTimezoneOffset() / 60)
  const gmtOffset = "GMT + " + offset

  return (
    <>
      <Tag
        sx={{ mr: 2, mb: 2 }}
      >{`${date.getDate()} ${month} ${date.getFullYear()}`}</Tag>
      <Tag sx={{ mr: 2, mb: 2 }}>{`${date.getHours()}:${
        date.getMinutes() < 10 ? "0" : ""
      }${date.getMinutes()} (${gmtOffset})`}</Tag>
    </>
  )
}

export default DateTimeBlocks
