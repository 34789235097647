import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { RCAImage } from "../../common/RCAImage"
import { Link } from "gatsby"
import { getRelativeStudentLink } from "../../../utils/data"

const SelectedStudent = ({ student, customSx = {} }) => {
  let studentRelativeLink

  if (student.url) {
    studentRelativeLink = getRelativeStudentLink(student?.url?.url)
  }
  return (
    <Box
      sx={{
        width: ["100%", "50%"],
        marginBottom: 4,
        ...customSx,
      }}
    >
      <Box>
        {student?.studentImage?.image && (
          <Link to={studentRelativeLink}>
            <RCAImage
              image={student.studentImage?.image?.asset?.fluid}
              alt={student.studentImage?.altText}
            />
          </Link>
        )}
      </Box>
      {student?.studentImage && (
        <Box sx={{ mt: "12px" }}>
          <Link to={studentRelativeLink}>
            {student.studentImage?.imageDescription}
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default SelectedStudent
