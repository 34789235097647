import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import { RCAImage } from "../../common/RCAImage"
import { toSanityLineBreaks } from "../../../utils/data"

const Curator = ({ curator }) => {
  return (
    <Grid columns={[1, 2]} gap={0}>
      <Box sx={{ variant: "layout.rightBorder" }}>
        <Box sx={{ variant: "layout.paddedItemTLR", mb: [7, 9] }}>
          <Box
            sx={{
              variant: "layout.contentWidthMedium",
              mt: [4, 5],
              "@media (min-width: 40em)": { pr: "80px" },
              "@media (min-width: 120em)": { pr: "200px" },
            }}
          >
            {curator.curatorName && <p>{curator?.curatorName}</p>}
            {curator?.curatorBio && (
              <p
                dangerouslySetInnerHTML={{
                  __html: toSanityLineBreaks(curator.curatorBio),
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      {curator.curatorPortrait?.image && (
        <Box sx={{ variant: "layout.paddedItem" }}>
          {curator.curatorPortrait?.image && (
            <RCAImage
              image={curator.curatorPortrait?.image?.asset?.fluid}
              alt={curator.curatorPortrait.altText}
            />
          )}
        </Box>
      )}
    </Grid>
  )
}

export default Curator
