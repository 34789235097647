import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid, Text } from "theme-ui"
import CollectionsBlock from "../CollectionsBlock"
import { ListItem } from "../../common/ListItem"

const HomepageCollections = props => {
  const collections = props.collections

  const checkCollections = () => {
    if (collections.featuredCollection || collections.collections) {
      if (
        collections.collections.length > 0 ||
        collections.featuredCollection
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <Box sx={{ pb: [6, "64px"] }}>
      {checkCollections() && (
        <ListItem
          icon="chevron"
          to="/collections"
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h2"
          // sx={{ py: [3, 4] }}
        >
          Collections
        </ListItem>
      )}
      <Grid
        columns={[1, 2]}
        gap={0}
        sx={{ mt: ["24px", "60px"], mb: ["24px", "100px"] }}
      >
        {/* Collections Intro */}
        {collections.collectionsIntroduction && (
          <Box sx={{ px: [3, 4] }}>
            <Box
              sx={{
                variant: "type.indexDescription",
              }}
            >
              <Box
                sx={{
                  pr: 3,
                  "@media (min-width: 40em)": { pr: "80px" },
                  "@media (min-width: 120em)": { pr: "200px" },
                }}
              >
                <Box>{collections.collectionsIntroduction}</Box>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>

      {/* Collections Block */}
      {collections.collections && collections.collections.length > 0 && (
        <CollectionsBlock
          collections={collections.collections}
          numColumns={3}
        />
      )}
    </Box>
  )
}

export default HomepageCollections
