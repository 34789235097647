import { useContext } from "react"
import { Index } from "react-instantsearch-dom"

/** @jsx jsx */
import { jsx, Grid, Box, useThemeUI } from "theme-ui"
import { alpha } from "@theme-ui/color"
import { RCAContext } from "./../../context/RCAContext"
import ResultsList from "./ResultsList"
import { useResponsiveValue } from "@theme-ui/match-media"

export default function Search({ indices, animating, onReportRef }) {
  const { searchBarWidth, bodyLocked, searchQuery } = useContext(RCAContext)
  const { theme } = useThemeUI()
  const borderWidth = useResponsiveValue(theme.sizes.borderWidth, {
    defaultIndex: 1,
  })

  const onRef = ref => {
    onReportRef(ref)
  }

  return (
    <div
      sx={{
        display: "flex",
      }}
    >
      <div
        sx={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          left: 0,
          zIndex: 2000,
          maxWidth: "100%",
        }}
      >
        <Grid
          as="nav"
          className={"searchNav"}
          gap={0}
          columns={[1, 2]}
          sx={{
            minHeight: "100%",
            backgroundColor: alpha("background", 0.6),
            marginRight: bodyLocked && searchBarWidth,
          }}
        >
          <Box
            ref={onRef}
            sx={{
              backgroundColor: "text",
              gridColumn: [1, 2],
              pt: theme.layout.header.height,
              // pb: theme.layout.footer.height,
              pb: [
                `calc(100px + env(safe-area-inset-bottom))`,
                `calc(40px + env(safe-area-inset-bottom))`,
              ],
              width: `calc(100% + ${borderWidth}px)`,
              marginLeft: `-${borderWidth}px`,
              height: "100vh",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
            }}
          >
            {searchQuery.length !== 0 &&
              indices.map(({ name, title }) => {
                return (
                  <Index key={name} indexName={name}>
                    <ResultsList name={name} title={title} />
                  </Index>
                )
              })}
          </Box>
        </Grid>
      </div>
    </div>
  )
}
