/** @jsx jsx */
import React, { useMemo, useContext, useCallback } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"

import Img from "gatsby-image"
import { jsx, Box, useThemeUI } from "theme-ui"
import { LazyImage } from "./LazyImage"

import { placeholderImageFluid } from "../../utils/common"

const RCAImage = ({
  image,
  alt,
  title,
  fluidOverrides = {},
  imageStyles = {},
  containerStyles = {},
  sx = {},
  ...props
}) => {
  const { theme } = useThemeUI()
  // const borderWidth = useResponsiveValue(theme.sizes.borderWidth)

  const imageObject = useMemo(() => image || placeholderImageFluid, [image])

  const sanitizedAlt = alt && alt.startsWith("http://") ? alt.substring(7) : alt

  const onLoad = useCallback(() => {
    window.dispatchEvent(
      new Event("tile-loaded", {
        bubbles: true,
      }),
    )
  }, [])

  return (
    <Box sx={{ position: "relative", ...sx }} {...props}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          borderWidth: theme.sizes.borderWidth,
          borderStyle: "solid",
          borderColor: "text",
          color: "primary",
          opacity: !image ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
          pointerEvents: "none",
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          lineHeight: 0,
          img: {
            maxWidth: "100%",
          },
          ...containerStyles,
        }}
      >
        {typeof image === "string" ? (
          <LazyImage
            src={image}
            alt={sanitizedAlt}
            title={title}
            {...imageStyles}
          />
        ) : (
          <Img
            fluid={{ ...imageObject, ...fluidOverrides }}
            {...imageStyles}
            alt={sanitizedAlt}
            title={title}
            onLoad={onLoad}
            loading="lazy"
          />
        )}
      </Box>
    </Box>
  )
}

RCAImage.propTypes = {
  // The gatsby sharp fluid object
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  // An alt attr for the image
  alt: PropTypes.string,

  // A title attr for the image
  title: PropTypes.string,

  // Any overrides to apply to the image object
  fluidOverrides: PropTypes.object,

  // The theme ui styling object
  sx: PropTypes.object,
}

export { RCAImage }
