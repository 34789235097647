import React from "react"
import { Box, Flex } from "theme-ui"
import { EventCard } from "./EventCard"

const EventItem = ({ event: eventNode, isOdd, evenTotal }) => {
  const variantName = isOdd
    ? "layout.paddedItemRightBorder"
    : "layout.paddedItem"

  const event = eventNode.node || eventNode

  return (
    <Flex
      sx={{
        p: [3, 4, 4],
        height: "100%",
        borderColor: "text",
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: isOdd ? [0, 2] : 0,
        borderBottomWidth: [1, 2],
        "&:nth-last-of-type(1)": {
          borderBottomWidth: 0,
        },
        ...(evenTotal
          ? {
              "@media (min-width: 40em)": {
                "&:nth-last-of-type(1), &:nth-last-of-type(2)": {
                  borderBottomWidth: 0,
                },
              },
            }
          : {}),
      }}
    >
      <EventCard contentSx={{ mb: [7, 9] }} {...event} />
    </Flex>
  )
}

export default EventItem
