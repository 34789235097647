import React, { useMemo } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

import { Card } from "../common/Card"
import { Tag } from "../common/Tag"
import {
  getGetDateTimeStringsArray,
  getValidTagsArray,
  getThemes,
} from "../../utils/data"

dayjs.extend(isBetween)

const EventCard = ({
  eventName,
  slug,
  strapline,
  eventDate,
  eventType,
  location,
  themes,
  contentSx = {},
}) => {
  const isLive = useMemo(() => {
    const date = dayjs(eventDate)
    const endDate = date.add(1, "hour").add(30, "minute")

    return dayjs().isBetween(date, endDate)
  }, [eventDate])

  const datetime = getGetDateTimeStringsArray(eventDate)

  let eventKind = null
  let tagThemes
  if (themes) {
    tagThemes = getThemes(themes)
    eventKind = tagThemes[0]
  }

  const tags = getValidTagsArray([
    // isLive ? null : datetime[0],
    datetime[0],
    datetime[1],
    //  Event Type not being used instead using themes due to problem with EventType in Sanity{ text: eventType },
    eventKind ? eventKind : null,
    { text: location },
    isLive ? { text: "Live", invert: true, blink: true } : null,
  ])

  return (
    <Card
      title={eventName}
      tags={tags}
      footerSlot={
        <Tag to={`/events/${slug.current}`} label={eventName}>
          Read More
        </Tag>
      }
      to={`/events/${slug.current}`}
      contentSx={contentSx}
    >
      <Box sx={{ pt: [2, 0], mt: "4px", pr: [0, "80px"] }}>{strapline}</Box>
    </Card>
  )
}

export { EventCard }
