import React, {
  useRef,
  useLayoutEffect,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react"
import { Flex, Box } from "theme-ui"

import { addScrollListener } from "../common/ScrollListener"
import { RCAContext } from "../../context/RCAContext"
import { addResizeListener } from "../common/ResizeListener"

const svgParts = [
  require.context("!@svgr/webpack?dimensions=false!./parts/1", false, /.svg/),
  require.context("!@svgr/webpack?dimensions=false!./parts/2", false, /.svg/),
  require.context("!@svgr/webpack?dimensions=false!./parts/3", false, /.svg/),
  require.context("!@svgr/webpack?dimensions=false!./parts/4", false, /.svg/),
]

const svgWrapperSx = {
  width: "100%",
  lineHeight: 0,
}

const stretchySx = {
  ...svgWrapperSx,
  display: "flex",
  flexGrow: 20,
}

const tackySx = {
  ...svgWrapperSx,
  display: "flex",
  flexGrow: 0,
}

const staticSx = {
  ...svgWrapperSx,
  flexGrow: 0,
  flexShrink: 0,
}

const SvgPart = ({ tacky, stretchy, children, isFirst }) => {
  const sxProp = useMemo(() => {
    if (tacky) {
      return tackySx
    }

    if (stretchy) {
      return stretchySx
    }

    return staticSx
  }, [tacky, stretchy])

  return <Box sx={{ ...sxProp, mt: isFirst ? 0 : "-1px" }}>{children}</Box>
}

const getSplits = idx => {
  const parts = svgParts[idx]

  const keys = parts.keys()
  const splits = new Array(4).fill(0).map((v, i) => {
    return keys
      .filter(k => k.includes(`${i + 1}_`))
      .map(k => ({
        key: k,
        component: parts(k),
        tacky: k.includes("tacky"),
        stat: k.includes("static"),
        stretchy: k.includes("stretchy"),
      }))
  })

  return splits
}

const LogoIdent = () => {
  const { isIos } = useContext(RCAContext)
  const [splits, setSplits] = useState(false)

  const wrapperRef = useRef()
  const containerRef = useRef()

  useLayoutEffect(() => {
    setSplits(getSplits(parseInt(document.body.dataset.ident || 0, 10)))

    const onScroll = () => {
      const rect = wrapperRef.current.getBoundingClientRect()
      containerRef.current.style.height = `${rect.height + rect.y}px`
    }

    onScroll()

    const scrollUnlisten = addScrollListener(onScroll)
    const resizeUnlisten = addResizeListener(onScroll)

    return () => {
      scrollUnlisten()
      resizeUnlisten()
    }
  }, [])

  const colSx = {
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%",

    // Safari only
    "@media not all and (min-resolution:.001dpcm)": {
      "@supports (-webkit-appearance:none)": {
        height: "-webkit-fill-available",
      },
    },

    // iOS
    "@supports (-webkit-touch-callout: none)": {
      height: "100%",
    },
    svg: { width: "100%" },
  }

  return (
    <Flex
      ref={wrapperRef}
      sx={{
        variant: "layout.bottomBorder",
        alignItems: "flex-end",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        overflow: "none",
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          height: "100%",
          color: "text",
          minHeight: "120vw",
          "@media (min-width: 30em)": {
            minHeight: "30vw",
          },
          maxWidth: "100%",
          transform: "translate3d(0,0,0)",
          willChange: "height",
          "@supports (-webkit-touch-callout: none)": {
            transition: "height 0.1s",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            "@supports (-webkit-touch-callout: none)": {
              gridTemplateRows: "calc(50% - 6px) calc(50% - 6px)",
              "@media (min-width: 30em)": {
                gridTemplateRows: "100%",
              },
            },
            "@media (min-width: 30em)": {
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            },
            p: [3, 4],
            gap: 3,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            flexWrap: "wrap",
            color: "text",
          }}
        >
          {splits &&
            splits.map((svgs, i) => (
              <Flex key={i} sx={colSx}>
                {svgs.map(({ component, key, ...rest }, idx) => {
                  const Svg = component.default

                  return (
                    <SvgPart key={key} isFirst={idx === 0} {...rest}>
                      <Svg />
                    </SvgPart>
                  )
                })}
              </Flex>
            ))}
        </Box>
      </Box>
    </Flex>
  )
}

export { LogoIdent }
