import React, { useCallback } from "react"
import { useContext } from "react"
import { RCAContext } from "../../../context/RCAContext"
import { ListItem } from "../../common/ListItem"
import { PlusIcon } from "../../common/PlusIcon"
import { List } from "../../common/List"
import { Collapsible } from "../../common/Collapsible"

const SchoolMenuItem = ({ school }) => {
  const { selectedMenu, setSelectedMenu } = useContext(RCAContext)

  const onSelectMenu = useCallback(() => {
    setSelectedMenu(
      selectedMenu === school.schoolName ? false : school.schoolName,
    )
  }, [school, selectedMenu, setSelectedMenu])

  return (
    <li>
      <ListItem
        onClick={onSelectMenu}
        leftBorder={false}
        topBorder={false}
        rightBorder={false}
        size="navigation"
        subMenuLevel="two"
        slot={
          <PlusIcon name="plus" toggle={selectedMenu === school.schoolName} />
        }
      >
        {school.schoolName}
      </ListItem>
      <Collapsible duration={250} open={selectedMenu === school.schoolName}>
        <List>
          <li>
            <ListItem
              to={`/schools/${school.slug.current}`}
              leftBorder={false}
              topBorder={false}
              rightBorder={false}
              size="navigation"
              icon="chevron"
              reverse
              justifyContent="flex-end"
              subMenuLevel="three"
            >
              School Introduction
            </ListItem>
          </li>
          {school.programmes.map(programme => (
            <li key={programme.slug.current}>
              <ListItem
                to={`/programmes/${programme.slug.current}`}
                leftBorder={false}
                topBorder={false}
                rightBorder={false}
                size="navigation"
                icon="chevron"
                reverse
                justifyContent="flex-end"
                subMenuLevel="three"
              >
                {programme.programmeName}
              </ListItem>
            </li>
          ))}
        </List>
      </Collapsible>
    </li>
  )
}

export default SchoolMenuItem
