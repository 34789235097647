/** @jsx jsx */
import React, { useMemo } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"

import { Flex, Text, jsx, useThemeUI, Button } from "theme-ui"
import { Link } from "gatsby"

import { iconKeys, Icon } from "./Icon"

// Styles for specific sizes
const itemSizes = {
  normal: theme => ({
    variant: "type.listPrimary",
    fontSize: [2, 3],
    px: [3, 4],
    py: ["5px", "7px"],
  }),
  large: theme => ({
    // variant: "type.listHeading",
    // fontSize: [6, 8],
    variant: "type.headline",
    px: [3, 4, 4],
    py: ["7px", "11px", "11px"],
  }),
  navigation: theme => ({
    variant: "type.navigation",
    px: [3, 4],
    pt: ["2px", "6px"],
    pb: ["2px", "5px"],
  }),
}

// Map for tag type additional styles
const itemTypes = {
  normal: () => ({}),
  link: (theme, invert, noHover) => ({
    "&:hover": noHover
      ? {}
      : {
          color: invert ? "text" : "background",
          backgroundColor: invert ? "background" : "text",
          textDecoration: "none",
        },
  }),
  button: (theme, invert, noHover) => ({
    button: {
      borderWidth: [0, 0],
    },
    "&:hover": noHover
      ? {}
      : {
          color: invert ? "text" : "background",
          backgroundColor: invert ? "background" : "text",
        },
  }),
}

const iconSize = {
  normal: "normal",
  large: "large",
}

const subMenus = {
  one: ["12px", "24px"],
  two: ["24px", "48px"],
  three: ["36px", "72px"],
}

const ListItem = ({
  reverse,
  justifyContent = "space-between",
  children,
  icon,
  to,
  onClick,
  leftBorder = true,
  rightBorder = true,
  topBorder = true,
  bottomBorder = true,
  size = "normal",
  tagName = "p",
  sx = {},
  slot = null,
  subMenuLevel,
  invert = false,
  noHover = false,
  ...props
}) => {
  const { theme } = useThemeUI()

  const itemType = useMemo(() => {
    if (onClick) {
      return "button"
    }

    if (to) {
      return "link"
    }

    return "normal"
  }, [to, onClick])

  const innerContent = useMemo(
    () => (
      <>
        <Text
          sx={{
            textAlign: "left",
            pt: tagName === "h2" ? 0 : "2px",
            pb: tagName === "h2" ? 0 : "2px",
            my: tagName === "h2" ? "-4px" : "0",
          }}
          as={tagName}
        >
          {children}
        </Text>
        {icon && (
          <Icon
            sx={{
              [reverse ? "mr" : "ml"]: ["12px", "24px"],
              flexShrink: 0,
            }}
            name={icon}
            size={iconSize[size]}
          />
        )}
        {slot}
      </>
    ),
    [icon, children, size, slot, tagName, reverse],
  )

  const parentSx = useMemo(() => {
    const obj = {
      display: "flex",
      alignItems: "center",
      flexDirection: reverse ? "row-reverse" : "row",
      justifyContent,

      path: {
        strokeWidth: size === "large" ? "1px" : "2px",
      },

      // Border widths
      borderTopWidth: topBorder ? "1px" : 0,
      borderRightWidth: rightBorder ? "1px" : 0,
      borderBottomWidth: bottomBorder ? "1px" : 0,
      borderLeftWidth: leftBorder ? "1px" : 0,
      "@media (min-width: 40em)": {
        borderTopWidth: topBorder ? "2px" : 0,
        borderRightWidth: rightBorder ? "2px" : 0,
        borderBottomWidth: bottomBorder ? "2px" : 0,
        borderLeftWidth: leftBorder ? "2px" : 0,
      },

      borderStyle: "solid",
      borderColor: invert ? theme.colors.background : theme.colors.text,
      backgroundColor: invert ? theme.colors.text : theme.colors.background,
      color: invert ? theme.colors.background : theme.colors.text,
      width: "100%",
      textDecoration: "none",
      mode: "blue",
      ...(itemSizes[size](theme) || {}),
      ...(itemTypes[itemType](theme, invert, noHover) || {}),
      ...sx,
    }

    if (subMenuLevel) {
      obj.pl = subMenus[subMenuLevel]
    }

    return obj
  }, [
    theme,
    sx,
    reverse,
    justifyContent,
    leftBorder,
    rightBorder,
    itemType,
    size,
    bottomBorder,
    topBorder,
    subMenuLevel,
    invert,
    noHover,
  ])

  if (itemType === "button") {
    return (
      <Button
        className={noHover && "no-hover"}
        onClick={onClick}
        sx={parentSx}
        {...props}
      >
        {innerContent}
      </Button>
    )
  }

  if (itemType === "link") {
    if (to.startsWith("http")) {
      return (
        <a
          className={noHover && "no-hover"}
          target="_blank"
          rel="noreferrer noopener"
          href={to}
          sx={parentSx}
          {...props}
        >
          {innerContent}
        </a>
      )
    }

    return (
      <Link className={noHover && "no-hover"} to={to} sx={parentSx} {...props}>
        {innerContent}
      </Link>
    )
  }

  return (
    <Flex className={noHover && "no-hover"} sx={parentSx} {...props}>
      {innerContent}
    </Flex>
  )
}

ListItem.propTypes = {
  // The size to display
  size: PropTypes.oneOf(["normal", "large", "navigation"]),

  // Whether the flex order should be reversed
  reverse: PropTypes.bool,

  // Whether the contents should be spaced
  justifyContent: PropTypes.string,

  // The main content
  children: PropTypes.node,

  // An icon to attach
  icon: PropTypes.oneOf(iconKeys),

  // A destination for a link
  to: PropTypes.string,

  // A callback for a button
  onClick: PropTypes.func,

  // Any theme-ui pass through
  sx: PropTypes.object,

  // Whether to attach a top border
  topBorder: PropTypes.bool,

  // Whether to attach a right border
  rightBorder: PropTypes.bool,

  // Whether to attach a left border
  leftBorder: PropTypes.bool,

  // Whether to attach a bottom border
  bottomBorder: PropTypes.bool,

  // The tag to render the children string in
  tagName: PropTypes.string,

  // An additional slot to add any content
  slot: PropTypes.node,

  subMenuLevel: PropTypes.string,

  invert: PropTypes.bool,
}

export { ListItem }
