import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const StudentWorkLinks = ({ workLinks, eventLink }) => {
  return (
    <Box>
      <Box sx={{ mb: [4, 5] }}>
        {eventLink && (
          <span>
            <a
              href={eventLink}
              aria-label={eventLink}
              sx={{ textDecoration: "underline" }}
              target="_blanck"
              rel="noreferrer"
            >
              <h4>Read more</h4>
            </a>
          </span>
        )}
      </Box>
      {workLinks?.length > 0 && workLinks[0].title !== "" && (
        <Box sx={{ mb: [2, 0] }}>
          <h4>In Collaboration with: </h4>
          {workLinks.map((worklink, index) => {
            return (
              <Box key={`work-link-${index}`}>
                <Box sx={{ mb: [0, 1] }}>
                  {worklink.title && worklink.title !== "" && (
                    <a href={worklink?.url} target="_blank" rel="noreferrer">
                      {worklink.title}
                    </a>
                  )}
                </Box>
                {worklink.description && (
                  <Box sx={{ mt: 3, mb: [3, 5] }}>
                    <span>{worklink.description}</span>
                  </Box>
                )}
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default StudentWorkLinks
