import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import { ListItem } from "../common/ListItem"
import { checkIfSponsorsEmpty } from "../../utils/data"
import Obfuscate from "react-obfuscate"
import StudentWorkLinks from "./StudentWorkLinks"

const StudentAbout = ({ student, studentAbout, programme }) => {
  const contactLinks = student.contacts?.length > 0 || student.links.length > 0

  const checkStringIsNotEmpty = item => {
    if (item.trim() !== "") {
      return false
    } else return true
  }

  let studentLinks = []

  if (student.links) {
    student.links.forEach((link, index) => {
      if (link.url.trim() !== "") {
        const obj = { url: link.url, title: link.title }
        studentLinks.push(obj)
      }
    })
  }

  return (
    <Box>
      <Grid columns={[1, 2]} gap={0}>
        <Box
          sx={{
            variant: "layout.rightBorder",
            height: "100%",
          }}
        >
          <Box
            sx={{
              variant: "layout.paddedItemTLR",
              borderBottomColor: "text",
              borderBottomStyle: "solid",
              borderBottomWidth: [1, 0],
            }}
          >
            {studentAbout?.text && (
              <Box
                sx={{
                  variant: "type.description",
                  pr: 3,
                  "@media (min-width: 40em)": { pr: "80px" },
                  "@media (min-width: 120em)": { pr: "200px" },
                  "p:lastOfType": { mb: 0 },
                  mb: [7, 9],
                }}
                dangerouslySetInnerHTML={{
                  __html: studentAbout.text,
                }}
              ></Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            mb: [7, 9],
            "> div": {
              mb: ["27px", 5],
            },
            "> div:last-child": {
              mb: 0,
            },
          }}
        >
          {contactLinks && (
            <Box>
              {student.contacts?.length > 0 && (
                <>
                  <ListItem
                    topBorder={false}
                    leftBorder={false}
                    rightBorder={false}
                    size="navigation"
                    tagName="p"
                  >
                    Contact
                  </ListItem>
                  {student.contacts &&
                    student.contacts.map((contact, index) => {
                      return (
                        <ListItem
                          key={`contact-${index}`}
                          topBorder={false}
                          leftBorder={false}
                          rightBorder={false}
                          size="navigation"
                          // tagName="p"
                          sx={{
                            // backgroundColor: "red",
                            ":hover": {
                              backgroundColor: "text",
                              color: "background",
                              a: {
                                color: "background",
                                textDecoration: "none",
                              },
                            },
                          }}
                        >
                          <>
                            {contact.type === "email" && (
                              <Obfuscate email={contact.identifier} />
                            )}
                            {contact.type === "phone" && (
                              <Obfuscate tel={contact.identifier} />
                            )}
                            {contact.type === "other" && contact.identifier}
                          </>
                        </ListItem>
                      )
                    })}
                </>
              )}

              {studentLinks &&
                studentLinks.map((link, index) => {
                  return (
                    <ListItem
                      key={`links-${index}`}
                      to={link.url}
                      topBorder={false}
                      leftBorder={false}
                      rightBorder={false}
                      size="navigation"
                      tagName="p"
                    >
                      {link.title ? link.title : link.url}
                    </ListItem>
                  )
                })}
            </Box>
          )}
          {/* )} */}

          <Box>
            <ListItem
              topBorder={contactLinks}
              leftBorder={false}
              rightBorder={false}
              size="navigation"
              tagName="p"
            >
              Degree Details
            </ListItem>
            {student.school && (
              <ListItem
                topBorder={false}
                leftBorder={false}
                rightBorder={false}
                size="navigation"
                tagName="p"
              >
                {student.school}
              </ListItem>
            )}
            {programme && (
              <ListItem
                to={`/programmes/${programme?.slug.current}`}
                icon="chevron"
                topBorder={false}
                leftBorder={false}
                rightBorder={false}
                size="navigation"
                tagName="p"
              >
                {programme?.programmeName}
              </ListItem>
            )}
          </Box>

          {checkIfSponsorsEmpty(student.sponsors) && (
            <Box sx={{ mb: [7, 9] }}>
              <Box>
                <ListItem
                  topBorder={true}
                  leftBorder={false}
                  rightBorder={false}
                  size="navigation"
                  tagName="p"
                >
                  Sponsors
                </ListItem>
                {student.sponsors.map((sponsor, index) => {
                  return (
                    <ListItem
                      key={`sponsor-${index}`}
                      to={sponsor.url}
                      topBorder={false}
                      leftBorder={false}
                      rightBorder={false}
                      size="navigation"
                      tagName="p"
                    >
                      {sponsor.title ? sponsor.title : sponsor.url}
                    </ListItem>
                  )
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default StudentAbout
