import React, { useContext } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, useThemeUI, Text } from "theme-ui"
import { motion, AnimatePresence } from "framer-motion"
import { RCAContext } from "../../context/RCAContext"
import { Tag } from "../common/Tag"

const ServiceWorkerRefresh = () => {
  const { swUpdated, navAnchored } = useContext(RCAContext)
  const { theme } = useThemeUI()

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      transition: {
        delay: 1,
      },
      opacity: 1,
    },
  }
  const onClick = () => window.location.reload()

  return (
    <AnimatePresence>
      {swUpdated && navAnchored && (
        <motion.div
          variants={variants}
          initial="initial"
          animate={"enter"}
          role="button"
          sx={{
            variant: "type.navigation",
            px: [3, 4],
            py: [3, 1],
            position: "fixed",
            width: "100%",
            height: ["auto", theme.layout.footer.height[1]],
            bottom: 0,
            right: 0,
            color: "background",
            backgroundColor: "text",
            zIndex: 15000,
            outline: 0,
            border: 0,
            margin: 0,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          onClick={onClick}
        >
          <Text as="span" sx={{ mb: [2, 0] }}>
            There is a new version of the site. Please click here or refresh the
            page
          </Text>
          <Tag onClick={onClick}>Reload</Tag>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export { ServiceWorkerRefresh }
