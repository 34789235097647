import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import SEO from "../components/navigation/seo"
import StoriesBlock from "../components/pagelayout/StoriesBlock"
import StoryItem from "../components/pagelayout/StoryItem"
import { ListItem } from "../components/common/ListItem"

export const query = graphql`
  query RcaBlogPageQuery {
    stories: allSanityStory(
      filter: { isFeatured: { eq: false } }
      sort: { fields: _createdAt, order: ASC }
    ) {
      edges {
        node {
          featuredImage {
            image {
              asset {
                fluid(maxHeight: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          programmesFocus {
            programmeName
            slug {
              current
            }
          }
          schoolsFocus {
            schoolName
            slug {
              current
            }
          }
          title
          strapline
          description
          slug {
            current
          }
        }
      }
    }
    storiesHome: sanitySingletonStories {
      description
      featuredStory {
        featuredImage {
          altText
          imageDescription
          image {
            asset {
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        programmesFocus {
          programmeName
          slug {
            current
          }
        }
        schoolsFocus {
          schoolName
          slug {
            current
          }
        }
        title
        strapline
        description
        slug {
          current
        }
      }
      stories {
        featuredImage {
          altText
          imageDescription
          image {
            asset {
              fluid(maxWidth: 960) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        programmesFocus {
          programmeName
          slug {
            current
          }
        }
        schoolsFocus {
          schoolName
          slug {
            current
          }
        }
        title
        strapline
        description
        slug {
          current
        }
      }
    }
  }
`

const storiesPage = props => {
  const stories = props.data.stories.edges
  const storiesHome = props.data.storiesHome

  const pageDescription = props.data.description

  return (
    <>
      <SEO title="RCA2020 Stories" description={pageDescription} />

      {/* Stories Page Title */}
      <Box>
        <ListItem
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h1"
        >
          Stories
        </ListItem>
      </Box>

      {/* Stories Description */}
      {storiesHome.description && (
        <Box
          sx={{
            variant: "layout.bottomBorder",
          }}
        >
          <Grid columns={[1, 2]} gap={0} sx={{ mt: [4, 7], mb: [7, 9] }}>
            <Box sx={{ px: [3, 4] }}>
              <Box sx={{ variant: "type.indexDescription" }}>
                <Box
                  sx={{
                    pr: 3,
                    "@media (min-width: 40em)": { pr: "80px" },
                    "@media (min-width: 120em)": { pr: "200px" },
                  }}
                >
                  {storiesHome.description}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}

      {/* Double Feature Block */}
      {storiesHome.stories && (
        <Box sx={{ mt: [3, 4], mb: [0, 7] }}>
          <StoriesBlock stories={storiesHome.stories} numColumns={2} />
        </Box>
      )}

      {/* Single Feature Block */}
      {storiesHome.featuredStory && (
        <Box sx={{ variant: "layout.topBorder" }}>
          <Box sx={{ variant: "layout.paddedItemTLR", mb: [4, 7] }}>
            <StoryItem
              story={storiesHome.featuredStory}
              aspectRatio={16 / 9}
              numColumns={1}
            />
          </Box>
        </Box>
      )}

      {/* All Stories filtered on 'not featured' */}
      {stories && (
        <Box sx={{ variant: "layout.topBorder" }}>
          <Box sx={{ mb: [5, 6], pb: [3, 0], pt: [3, 4] }}>
            <StoriesBlock stories={stories} numColumns={3} marginBottom={7} />
          </Box>
        </Box>
      )}
    </>
  )
}

export default storiesPage
