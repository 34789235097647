import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import InstagramEmbed from "react-instagram-embed"

const Instagram = ({ urlUri }) => {
  return (
    <Box className="rca-embed-platform instagram">
      <InstagramEmbed
        url={urlUri}
        maxWidth={"100%"}
        hideCaption={false}
        containerTagName="div"
        protocol=""
        injectScript
        onLoading={() => {}}
        onSuccess={() => {}}
        onAfterRender={() => {}}
        onFailure={() => {}}
      />
    </Box>
  )
}

export default Instagram
