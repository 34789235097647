import React, { useContext, useState, useEffect } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"

/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { motion, AnimatePresence } from "framer-motion"
import { RCAContext } from "../../../context/RCAContext"

const MenuPanel = ({ keyName, children, open }) => {
  const { theme } = useThemeUI()
  const { navAnchored } = useContext(RCAContext)
  const [animating, setAnimating] = useState(false)

  const variants = {
    open: { y: 0 },
    closed: { y: "-100%" },
    transition: {
      type: theme.animation.menu.transition.type,
      stiffness: theme.animation.menu.transition.stiffness,
      damping: theme.animation.menu.transition.damping,
    },
  }

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          key={keyName}
          initial={"closed"}
          animate={open ? "open" : "closed"}
          exit={open ? "closed" : "open"}
          transition={variants.transition}
          onAnimationComplete={() => setAnimating(false)}
          onAnimationStart={() => setAnimating(true)}
          variants={variants}
          sx={{
            width: ["100vw", "100vw"],
            maxWidth: "100%",
            height: "100vh",
            position: !navAnchored ? "absolute" : "fixed",
            zIndex: 90,
            top: 0,
            left: 0,
            willChange: "transform",
          }}
        >
          {children(animating)}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

MenuPanel.propTypes = {
  children: PropTypes.func,
  keyName: PropTypes.string,
  open: PropTypes.bool,
}

export { MenuPanel }
