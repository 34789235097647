import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import HomepageEvents from "./HomepageEvents"
import HomepageCollections from "./HomepageCollections"
import HomepageStories from "./HomepageStories"
import HomepageTags from "./HomepageTags"
import HomepageStudents from "./HomepageStudents"

const HomepageFeatures = ({ pageFeatures, tags }) => {
  const events = pageFeatures.find(f => f.events).events
  const collections = pageFeatures.find(f => f.collections).collections
  const stories = pageFeatures.find(f => f.stories).stories

  return (
    <Box>
      <HomepageEvents events={events} />
      <HomepageCollections collections={collections} />
      <HomepageStories stories={stories} />
      {/* <HomepageStudents /> */}
      <HomepageTags tags={tags} />
    </Box>
  )
}

export default HomepageFeatures
