import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import EventItem from "./EventItem"

const EventsBlock = props => {
  const events = props.events

  return (
    <Grid columns={[1, 2]} gap={0}>
      {events.map((event, index) => {
        return (
          <EventItem
            key={`event-itme-${index}`}
            index={index}
            event={event}
            isOdd={(index + 1) % 2}
            evenTotal={events.length % 2 === 0}
          />
        )
      })}
    </Grid>
  )
}

export default EventsBlock
