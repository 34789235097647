import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Box, Spinner } from "theme-ui"

import SEO from "../navigation/seo"
import { ListItem } from "../common/ListItem"
import { directFilenameImage } from "../../fragments/images"
import { searchClient } from "../../utils/search"
import { RCAImage } from "../common/RCAImage"
import { MasonryGrid } from "../common/MasonryGrid/MasonryGrid"

const tagIndex = searchClient.initIndex("students")

const IndividualTag = ({ tagName }) => {
  const [hits, setHits] = useState(false)

  useEffect(() => {
    tagIndex
      .search(`${tagName}!@!`, {
        typoTolerance: false,
        hitsPerPage: 50,
      })
      .then(res => {
        setHits(res.hits)
      })
  }, [tagName])

  return (
    <>
      <SEO title={`Tags: ${tagName}`} />
      <Box>
        <ListItem
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          size="large"
          tagName="h1"
          sx={{
            textTransform: "capitalize",
          }}
        >
          Tag Results: {tagName}
        </ListItem>
      </Box>
      <Box p={[3, 4]}>
        {hits ? (
          <MasonryGrid>
            {({ itemWidth }) =>
              hits.map(hit => {
                return (
                  <Box
                    key={hit.slug}
                    mb={[3, 4]}
                    sx={{
                      width: itemWidth,
                    }}
                  >
                    <Link to={`/students/${hit.slug}`}>
                      <RCAImage
                        image={
                          hit.image ? directFilenameImage(hit.image) : null
                        }
                      />
                    </Link>
                  </Box>
                )
              })
            }
          </MasonryGrid>
        ) : (
          <Spinner sx={{ color: "text" }} />
        )}
      </Box>
    </>
  )
}

IndividualTag.propTypes = {
  tagName: PropTypes.string,
}

export { IndividualTag }
