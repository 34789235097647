/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useLayoutEffect, useRef, useState, useContext } from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { jsx, useThemeUI, Flex } from "theme-ui"
import { Global } from "@emotion/core"
import { Container } from "theme-ui"

import Header from "./navigation/header"
import Footer from "./navigation/footer"
import PageFooter from "./navigation/PageFooter"
import { LogoIdent } from "./logoIdent/logoIdent"
import smoothscroll from "smoothscroll-polyfill"
import { RCAContext } from "../context/RCAContext"
import { ServiceWorkerRefresh } from "./pagelayout/ServiceWorkerRefresh"

const Layout = props => {
  const { hideHeader } = useContext(RCAContext)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { theme } = useThemeUI()

  const layoutHeaderRef = useRef(null)

  useLayoutEffect(() => {
    // on load
    // make smoothscroll work on safari and other crap browsers
    smoothscroll.polyfill()
  }, [])
  // scroll to header
  const scrollToHeader = () => {
    if (layoutHeaderRef.current) {
      layoutHeaderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  // todo:
  // useLayoutEffect perhaps to check hydration before rendering

  return (
    <>
      <Global />
      <a
        href="#maincontent"
        sx={{
          position: "absolute",
          top: 3,
          left: "-9999px",
          zIndex: 10000,
          backgroundColor: "background",
          color: "text",
          p: 3,
          "&:focus,&:active": {
            left: 3,
          },
        }}
      >
        Skip to main content
      </a>
      {!hideHeader && (
        <Flex
          backgroundColor="background"
          sx={{
            width: "100vw",
            height: ["calc(100vh - 20vw)", "calc(100vh - 10vw)"],
            maxWidth: "100%",
            position: "relative",
            zIndex: 9999,
            cursor: "pointer",
          }}
          onClick={() => scrollToHeader()}
        >
          <LogoIdent />
        </Flex>
      )}
      <div ref={layoutHeaderRef} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: theme =>
            `calc(100vh - ${theme.layout.header.height[1]}px)`,
        }}
        key={"container-" + props.location.pathname}
      >
        <main id="maincontent" sx={{ flexGrow: 1 }}>
          {props.children}
        </main>
        <PageFooter
          sx={{
            mb: theme.layout.footer.height,
          }}
        />
      </Container>
      <Footer />
      <ServiceWorkerRefresh />
    </>
  )
}

export default Layout
