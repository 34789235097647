import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
// import Portrait from "../../commonblocks/Portrait"

const PresenterGuest = ({ presenterGuest, index }) => {
  return (
    <Box sx={{ pt: [4, 5], width: ["100%", "50%"], pb: [3, 7] }}>
      {/* Name */}
      {presenterGuest.name && (
        <Box sx={{ mb: [3, 5] }}>
          <h3 sx={{ variant: "type.description" }}>
            {presenterGuest.name}
            {presenterGuest.role && ` - ${presenterGuest.role}`}
          </h3>
        </Box>
      )}

      {/* Short Bio */}
      <Box sx={{ maxWidth: ["100%", "75%"] }}>
        {presenterGuest.shortBio && <div>{presenterGuest.shortBio}</div>}
      </Box>
    </Box>
  )
}

export default PresenterGuest
