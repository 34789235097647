import React from "react" // eslint-disable-line no-unused-vars
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box, Grid } from "theme-ui"
import SEO from "../components/navigation/seo"
import HomePageFeatures from "../components/pagelayout/homepage/HomepageFeatures"
import { RCALogo } from "../components/common/RCALogo"
import { RCAEmbed } from "../components/common/RCAEmbed/RCAEmbed"

export const query = graphql`
  query HomePageQuery {
    home: sanitySingletonHomepage {
      title
      description
      pageFeatures {
        collections {
          collectionsIntroduction
          # featuredCollection {
          #   featuredImage {
          #     altText
          #     imageDescription
          #     image {
          #       asset {
          #         fluid(maxWidth: 600) {
          #           ...GatsbySanityImageFluid
          #         }
          #       }
          #     }
          #   }
          #   # themes {
          #   #   themeName
          #   #   slug {
          #   #     current
          #   #   }
          #   # }
          #   programmesFocus {
          #     programmeName
          #     slug {
          #       current
          #     }
          #   }
          #   schoolsFocus {
          #     schoolName
          #     slug {
          #       current
          #     }
          #   }
          #   collectionName
          #   description
          #   slug {
          #     current
          #   }
          # }
          collections {
            featuredImage {
              altText
              imageDescription
              image {
                asset {
                  fluid(maxWidth: 600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            # themes {
            #   themeName
            #   slug {
            #     current
            #   }
            # }
            programmesFocus {
              programmeName
              slug {
                current
              }
            }
            schoolsFocus {
              schoolName
              slug {
                current
              }
            }
            collectionName
            description
            slug {
              current
            }
          }
        }
        stories {
          featuredStory {
            featuredImage {
              image {
                asset {
                  fluid(maxWidth: 1920) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              altText
              imageDescription
            }
            # themes {
            #   themeName
            #   slug {
            #     current
            #   }
            # }
            programmesFocus {
              programmeName
              slug {
                current
              }
            }
            schoolsFocus {
              schoolName
              slug {
                current
              }
            }
            title
            strapline
            description
            slug {
              current
            }
          }
          stories {
            featuredImage {
              altText
              imageDescription
              image {
                asset {
                  fluid(maxWidth: 960) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            # themes {
            #   themeName
            #   slug {
            #     current
            #   }
            # }
            programmesFocus {
              programmeName
              slug {
                current
              }
            }
            schoolsFocus {
              schoolName
              slug {
                current
              }
            }
            title
            strapline
            description
            slug {
              current
            }
          }
        }
        events {
          featuredEvent {
            eventName
            eventDate
            eventType
            strapline
            location
            slug {
              current
            }
            themes {
              slug {
                current
              }
              themeName
            }
            featuredImage {
              image {
                asset {
                  fluid(maxWidth: 960) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          events {
            eventName
            eventDate
            eventType
            strapline
            location
            themes {
              slug {
                current
              }
              themeName
            }
            slug {
              current
            }
          }
        }
      }
    }
    tags: allTag(sort: { fields: total, order: DESC }, limit: 100) {
      edges {
        node {
          id
          slug
          text
          total
          displayText
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const pageDescription = data.home.description

  return (
    <>
      <SEO title="RCA2020" description={pageDescription} />
      <Grid columns={[1, 2]} gap={0} sx={{ pt: [4, 7], pb: [6, 7, 7] }}>
        <Box sx={{ variant: "layout.paddedX" }}>
          <h1
            sx={{
              mt: "-8px",
              mb: ["28px", 0, 0],
              width: ["350px"],
              variant: "type.headline",
            }}
          >
            Welcome to RCA2020
          </h1>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ px: [3, 4], pb: 0, mb: [9, 11] }}>
            <Box sx={{ variant: "type.bodyStories" }}>
              <Box
                sx={{
                  pr: 3,
                  "@media (min-width: 40em)": { pr: "80px" },
                  "@media (min-width: 120em)": { pr: "200px" },
                }}
              >
                {data.home.description}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ml: [3, 4],
              width: ["172px", "216px"],
            }}
          >
            <RCALogo />
          </Box>
        </Box>
      </Grid>
      <Box sx={{ variant: "layout.paddedItemTopBorder" }}>
        <RCAEmbed mediaUrl={"https://vimeo.com/444887024"} />
      </Box>
      <Box>
        <HomePageFeatures
          pageFeatures={data.home.pageFeatures}
          tags={data.tags}
        />
      </Box>
    </>
  )
}

export default IndexPage
