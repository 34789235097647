/** @jsx jsx */
import React, { useEffect, useRef, useCallback, useState } from "react" // eslint-disable-line no-unused-vars
import { useContext } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import { jsx, Grid, Box, useThemeUI } from "theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"

import { RCAContext, MENUS } from "./../../context/RCAContext"
import Menu from "./menu/Menu"
import Search from "../search"
import { ListItem } from "../common/ListItem"
import { MenuPanel } from "./menu/MenuPanel"
import SearchInput from "../search/input"
import { addScrollListener } from "../common/ScrollListener"
import { clickOutside } from "../../utils/common"
import { searchClient, searchIndices } from "../../utils/search"
import { RotateIcon } from "../common/RotateIcon"

const Header = () => {
  const { theme } = useThemeUI()

  // Track the header so we can see if its stuck at the top
  const headerRef = useRef(null)
  const searchRef = useRef(null)
  const searchInputWrapperRef = useRef(null)
  const menuRef = useRef(null)

  const [initialCheck, setInitialCheck] = useState(false)
  const {
    isNavOpen,
    isSearchOpen,
    toggleMenuOpen,
    setNavAnchored,
    navAnchored,
    setBodyLocked,
    setSearchQuery,
    closeMenu,
    currentMenuRef,
  } = useContext(RCAContext)
  const breakPointIndex = useBreakpointIndex({ defaultIndex: 0 })

  const checkHeaderAnchor = useCallback(() => {
    const { top } = headerRef.current.getBoundingClientRect()

    if (top < 1 && !navAnchored) {
      // window.scrollTo(0, 0)

      setNavAnchored(true)
    } else if (top > 1 && navAnchored) {
      setNavAnchored(false)
    }
  }, [navAnchored, setNavAnchored])

  useEffect(() => {
    if (headerRef.current) {
      if (!initialCheck) {
        checkHeaderAnchor()
        setInitialCheck(true)
      }

      return addScrollListener(checkHeaderAnchor)
    }
  }, [checkHeaderAnchor, initialCheck])

  // only in mobile mode
  // make menu and search fullscreen when open
  const headerGrid =
    breakPointIndex === 0 && !isNavOpen && !isSearchOpen ? 2 : 1
  const hideMenu = breakPointIndex === 0 && isSearchOpen ? "none" : "block"
  const hideSearch = breakPointIndex === 0 && isNavOpen ? "none" : "block"

  const scrollToHeader = () => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  const headerToggle = toggle => {
    toggleMenuOpen(toggle)
    if (!navAnchored) {
      scrollToHeader()
    }
  }

  // disable body scroll
  // uncomment to use
  useEffect(() => {
    let locked = true

    if (isNavOpen && navAnchored) {
      disableBodyScroll(menuRef.current, { reserveScrollBarGap: true })
    } else if (isSearchOpen && navAnchored) {
      disableBodyScroll(searchRef.current, { reserveScrollBarGap: true })
    } else {
      locked = false
      clearAllBodyScrollLocks()
    }

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setBodyLocked(locked)
      })
    })
  }, [isNavOpen, isSearchOpen, navAnchored, setBodyLocked])

  const onCloseSearch = useCallback(() => {
    if (currentMenuRef.current !== MENUS.NAV) {
      closeMenu()
    }
    setSearchQuery(``)
  }, [closeMenu, setSearchQuery, currentMenuRef])

  useEffect(() => {
    if (isSearchOpen && searchRef.current) {
      return clickOutside(searchRef.current, onCloseSearch, (el, evt) => {
        return (
          evt.target !== el &&
          !el.contains(evt.target) &&
          evt.target !== searchInputWrapperRef.current &&
          !searchInputWrapperRef.current.contains(evt.target)
        )
      })
    }
  }, [isSearchOpen, onCloseSearch])

  // padding the menu is possible as below
  // const setOverflowHidden = options => {
  //   // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  //   // the responsiveness for some reason. Setting within a setTimeout fixes this.
  //   setTimeout(() => {
  //     // If previousBodyPaddingRight is already set, don't set it again.
  //     if (previousBodyPaddingRight === undefined) {
  //       const reserveScrollBarGap = !!options && options.reserveScrollBarGap === true;
  //       const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

  //       if (reserveScrollBarGap && scrollBarGap > 0) {
  //         previousBodyPaddingRight = document.body.style.paddingRight;
  //         document.body.style.paddingRight = `${scrollBarGap}px`;
  //       }
  //     }

  //     // If previousBodyOverflowSetting is already set, don't set it again.
  //     if (previousBodyOverflowSetting === undefined) {
  //       previousBodyOverflowSetting = document.body.style.overflow;
  //       document.body.style.overflow = 'hidden';
  //     }
  //   });
  // };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={searchIndices[0].name}
      onSearchStateChange={({ query }) => setSearchQuery(query)}
    >
      <header
        ref={headerRef}
        sx={{
          position: "sticky",
          bg: "background",
          variant: "styles.header",
          height: theme.layout.header.height,
          width: "100%",
          top: 0,
          zIndex: 2000,
        }}
      >
        <Box
          sx={{
            zIndex: 100,
            position: "relative",
          }}
        >
          <Grid
            as="nav"
            gap={0}
            columns={[headerGrid, 2]}
            sx={{
              backgroundColor: "background",
            }}
          >
            <Box
              sx={{
                height: theme.layout.header.height,
                display: hideMenu,
              }}
            >
              <ListItem
                onClick={() => headerToggle(MENUS.NAV)}
                topBorder={false}
                rightBorder={true}
                leftBorder={false}
                size="navigation"
                slot={<RotateIcon name="plus" toggle={isNavOpen} />}
                sx={{ height: "100%" }}
              >
                Menu
              </ListItem>
            </Box>
            <Box
              ref={searchInputWrapperRef}
              sx={{
                position: "relative",
                height: theme.layout.header.height,
                display: hideSearch,
              }}
            >
              <SearchInput
                placeholder="Search"
                id="search"
                onFocus={() => headerToggle(MENUS.SEARCH)}
                onClose={closeMenu}
                focused={isSearchOpen}
              />
              {/* <ListItem
              onClick={() => headerToggle(MENUS.SEARCH)}
              topBorder={false}
              rightBorder={false}
              leftBorder={false}
              size="navigation"
              slot={
                isSearchOpen ? (
                  <Icon name="plus" sx={{ transform: `rotate(45deg)` }} />
                ) : (
                  <Icon name="search" />
                )
              }
              noHover={isSearchOpen}
              invert={isSearchOpen}
            >
              Search
            </ListItem> */}
            </Box>
          </Grid>
        </Box>

        <MenuPanel keyName="menu" open={isNavOpen}>
          {animating => (
            <Menu
              animating={animating}
              onReportRef={ref => (menuRef.current = ref)}
            />
          )}
        </MenuPanel>

        <MenuPanel keyName="menu" open={isSearchOpen}>
          {animating => (
            <Search
              indices={searchIndices}
              animating={animating}
              onReportRef={ref => (searchRef.current = ref)}
            />
          )}
        </MenuPanel>
      </header>
    </InstantSearch>
  )
}
export default Header
