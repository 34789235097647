import React from "react" // eslint-disable-line no-unused-vars
import ReactPlayer from "react-player"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Vimeo = ({ urlUri }) => {
  return (
    <Box
      className="rca-embed-platform vimeo"
      sx={{
        position: "relative",
        pt: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
      }}
    >
      <ReactPlayer
        url={urlUri}
        config={{
          vimeo: {
            playerOptions: {
              byline: true,
              color: "#000099",
            },
          },
        }}
        controls={true}
        playing={false}
        width="100%"
        height="100%"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </Box>
  )
}

export default Vimeo
