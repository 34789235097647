import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const InDesign = ({ urlUri }) => {
  return (
    <Box
      className="rca-embed-platform issuu"
      sx={{
        position: "relative",
        pt: "50%" /* Player ratio: 100 / (1280 / 720) */,
      }}
    >
      <iframe
        // allowFullScreen
        allow="fullscreen"
        src={urlUri}
        title={urlUri}
        sx={{
          border: "none",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </Box>
  )
}

export default InDesign
