import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react"
import { globalHistory } from "@reach/router"
import { detectTouched, isIOS } from "../utils/common"

export const MENUS = {
  CLOSED: "closed",
  NAV: "nav",
  SEARCH: "search",
}

const defaultValues = {
  currentMenu: MENUS.CLOSED,
  currentMenuRef: null,
  selectedMenu: null,
  selectedTertiaryMenu: null,
  toggleMenuOpen: () => {},
  closeMenu: () => {},
  setSelectedMenu: () => {},
  setSelectedTertiaryMenu: () => {},
  isNavOpen: false,
  isSearchOpen: false,
  navAnchored: false,
  setNavAnchored: () => {},
  searchBarWidth: 0,
  isIos: false,
  swUpdated: false,
}

export const RCAContext = createContext(defaultValues)

export const RCAProvider = ({ children }) => {
  const [navAnchored, setNavAnchored] = useState(false)
  const [currentMenu, setCurrentMenu] = useState(MENUS.CLOSED)
  const currentMenuRef = useRef(currentMenu)
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [selectedTertiaryMenu, setSelectedTertiaryMenu] = useState(null)
  const [searchBarWidth, setSearchBarWidth] = useState(0)
  const [bodyLocked, setBodyLocked] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isTouched, setIsTouched] = useState(false)
  const [swUpdated, setSwUpdated] = useState(false)
  const isIos = useRef(false)

  const isNavOpen = useMemo(() => {
    return currentMenu === MENUS.NAV
  }, [currentMenu])

  const isSearchOpen = useMemo(() => {
    return currentMenu === MENUS.SEARCH
  }, [currentMenu])

  const toggleMenuOpen = useCallback(
    (type = MENUS.NAV) => {
      setCurrentMenu(currentMenu === type ? MENUS.CLOSED : type)
      currentMenuRef.current = currentMenu === type ? MENUS.CLOSED : type
      if (currentMenu === type || type === MENUS.CLOSED) setSelectedMenu(null)
    },
    [currentMenu],
  )

  const closeMenu = useCallback(() => {
    setCurrentMenu(MENUS.CLOSED)
  }, [])

  useEffect(() => {
    const historyUnlisten = globalHistory.listen(() => {
      setCurrentMenu(MENUS.CLOSED)
    })

    const touchUnlisten = detectTouched(touch => {
      setIsTouched(touch)
    })

    isIos.current = isIOS()

    window.addEventListener("sw-updated", () => {
      setSwUpdated(true)
    })

    return () => {
      historyUnlisten()
      touchUnlisten()
    }
  }, [])

  useEffect(() => {
    if (navAnchored && !isTouched && !hideHeader) {
      // check if touch device
      if (isIos.current) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            setHideHeader(true)
            window.scrollTo(0, window.scrollY - window.innerHeight)
          })
        })
      } else {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            setHideHeader(true)
          })
        })
      }
    }
  }, [navAnchored, isTouched, hideHeader])

  useLayoutEffect(() => {
    setSearchBarWidth(window.innerWidth - document.documentElement.clientWidth)
  }, [])

  return (
    <RCAContext.Provider
      value={{
        currentMenu,
        currentMenuRef,
        selectedMenu,
        selectedTertiaryMenu,
        toggleMenuOpen,
        closeMenu,
        setSelectedMenu,
        setSelectedTertiaryMenu,
        isNavOpen,
        isSearchOpen,
        navAnchored,
        setNavAnchored,
        searchBarWidth,
        setSearchBarWidth,
        bodyLocked,
        setBodyLocked,
        searchQuery,
        setSearchQuery,
        hideHeader,
        setHideHeader,
        isIos: isIos.current,
        swUpdated,
      }}
    >
      {children}
    </RCAContext.Provider>
  )
}
