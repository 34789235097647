import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { RCAEmbed } from "../common/RCAEmbed/RCAEmbed"

const MediaTextBlock = ({ mediaText }) => {
  return (
    <Box>
      {mediaText?.media?.mediaUrl && (
        <Box>
          <Box>
            <RCAEmbed mediaUrl={mediaText.media.mediaUrl} />
          </Box>
          <Box sx={{ mt: 3 }}>
            {mediaText.media.title} {mediaText.description}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default MediaTextBlock
