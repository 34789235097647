import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Sponsors = ({ sponsors }) => {
  let sponsorNames = ""

  let i = ""
  for (i = 0; i < sponsors.length; i++) {
    if (i <= sponsors.length - 2) {
      sponsorNames += sponsors[i].sponsorName + ", "
    } else sponsorNames += sponsors[i].sponsorName
  }

  return <Box sx={{ mb: 20, display: "inline" }}>{sponsorNames}</Box>
}

export default Sponsors
