import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { ExpandingSection } from "../../common/ExpandingSection"
import TagsFromStudents from "../TagsFromStudents"

// const LazyTagsFromStudents = Loadable(() => import("../TagsFromStudents"))

const HomepageTags = ({ tags }) => {
  return (
    <ExpandingSection initialExpanded title="Tags">
      <Box sx={{ mb: ["70px", 8] }}>
        <TagsFromStudents tags={tags.edges} hasMore />
      </Box>
    </ExpandingSection>
  )
}

export default HomepageTags
