import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

import PresenterGuest from "./PresenterGuest"

const PresenterGuests = ({ presenterGuests }) => {
  return (
    <Flex
      sx={{
        mb: ["48px", 0],
        flexWrap: "wrap",
        alignContent: "space-between",
        "& > div:nth-of-type(even)": {
          paddingLeft: [0, 4],
        },
        "& > div:nth-of-type(odd)": {
          paddingRight: [0, 4],
          borderRightStyle: "solid",
          borderRightWidth: [0, 2],
          borderRightColor: "text",
        },
      }}
    >
      {presenterGuests?.map((presenterGuest, index) => {
        return (
          <PresenterGuest
            key={`presenter-${index}`}
            index={index}
            presenterGuest={presenterGuest}
            sx={{ width: ["100%", "50%"] }}
          />
        )
      })}
    </Flex>
  )
}

export default PresenterGuests
