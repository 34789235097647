import React from "react"

import { Box, useColorMode } from "theme-ui"

import { ReactComponent as RCASvg } from "../../images/rca-logo.svg"
import { ReactComponent as RCADarkSvg } from "../../images/rca-logo-dark.svg"
const RCALogo = ({ sx = {} }) => {
  const [colorMode] = useColorMode()

  return (
    <Box
      sx={{
        color: "text",
        path: {
          fill: "currentColor",
        },
        ...sx,
        pb: 0,
      }}
    >
      {colorMode === "blue" || colorMode === "dark" ? (
        <RCASvg />
      ) : (
        <RCADarkSvg />
      )}
    </Box>
  )
}

export { RCALogo }
