export const checkEitherExist = (one, orOther) => {
  if (one || orOther) {
    return true
  } else {
    return false
  }
}

export const checkEitherHasItems = (one, orOther) => {
  let check = false
  if (one) {
    if (one.length > 0) check = true
  }

  if (orOther) {
    if (orOther.length > 0) check = true
  }

  return check ? true : false
}

export const getGetDateTimeStringsArray = isodatetime => {
  const date = new Date(isodatetime)
  let datetime = ["hello"]

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const month = months[date.getMonth()]
  const offset = -(date.getTimezoneOffset() / 60)
  const gmtOffset = "GMT + " + offset

  datetime[0] = { text: `${date.getDate()} ${month} ${date.getFullYear()}` }
  datetime[1] = {
    text: `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()} (${gmtOffset})`,
  }
  return datetime
}

export const checkEventIsPresentFutureOrFeatured = event => {
  var now = new Date()
  now = now.toISOString()

  const dateEvent = Date.parse(event.node.eventDate) + 10800000 // 3 hour in past offset
  const nowDate = Date.parse(now)

  if (dateEvent > nowDate || event.node.isFeatured === true) {
    return true
  } else {
    return false
  }
}

export const getValidTagsArray = arrayItems => {
  return arrayItems.filter((item, index) => item && item.text !== null)
}

export const getThemes = themes => {
  let tags = []

  themes.forEach((theme, index) => {
    const obj = { text: theme.themeName }
    // With Links below
    // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
    tags[index] = obj
  })
  return tags
}

export const getSchoolProgrammesFocus = (schoolsFocus, programmesFocus) => {
  const tags = []

  if (schoolsFocus) {
    schoolsFocus.forEach((school, index) => {
      const obj = { text: school.schoolName }
      // With Links below
      // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
      tags.push(obj)
    })
  }

  if (programmesFocus) {
    programmesFocus.forEach((programme, index) => {
      const obj = { text: programme.programmeName }
      // With Links below
      // const obj = { text: theme.themeName, to: `/themes/${theme.slug.current}` }
      tags.push(obj)
    })
  }

  if (tags.length > 2) {
    return [{ text: "Cross-College" }]
  }

  return tags
}

export const toLineBreaks = text => {
  return text
    .trim()
    .split(/\n/)
    .join("<br />")
}

export const toSanityLineBreaks = text => {
  if (text)
    return text
      .trim()
      .split(/\n/)
      .join("<br />")
      .replace(/\\n/g, "")
  else return ""
}

export const checkIfSponsorsEmpty = sponsors => {
  if (sponsors?.length > 0 && sponsors[0].title !== "") return true
  else return false
}

export const checkIfEventsEmpty = sponsors => {
  if (sponsors?.length > 0 && sponsors[0].title !== "") return true
  else return false
}

export const getRelativeStudentLink = studentUrl => {
  if (studentUrl.includes("/students/")) {
    const urlArray = studentUrl.split("/students/")
    const relativeUrl = "/students/" + urlArray[urlArray.length - 1]
    return relativeUrl
  }

  if (studentUrl.includes("/programmes/")) {
    const urlArray = studentUrl.split("/programmes/")
    const relativeUrl = "/programmes/" + urlArray[urlArray.length - 1]
    return relativeUrl
  }
}
