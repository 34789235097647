import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import StoryItem from "./StoryItem"

const StoriesBlock = ({ stories, numColumns, marginBottom = 0 }) => {
  return (
    <Grid
      columns={[1, numColumns]}
      gap={[3, 4]}
      sx={{ variant: "layout.paddedItemLR" }}
    >
      {stories.map((story, index) => {
        return (
          <Box key={`story-item-${index}`} sx={{ mb: ["16px", marginBottom] }}>
            <StoryItem
              key={`/story-item${index}`}
              story={story}
              aspectRatio={3 / 2}
            />
          </Box>
        )
      })}
    </Grid>
  )
}

export default StoriesBlock
